import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, useColorModeValue, Table, Thead, Tbody, Tr, Th, Td, Tag, TagLabel, Center, Spinner, Alert, AlertIcon, SimpleGrid, Button, Flex } from '@chakra-ui/react';
import { getPlans, getUserTransactions } from '../../../Service';
import { useUserContext } from '../../../contexts/UserContext';

const Billing = () => {
  const bgColor = useColorModeValue("#fff", "navy.800");
  const textColor = useColorModeValue("navy.700", "white");

  const [plans, setPlans] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 5;

  const { founder } = useUserContext();

  useEffect(() => {
    const token = localStorage.getItem("user-token");

    const fetchData = async () => {
      try {
        const [plansResponse, transactionsResponse] = await Promise.all([
          getPlans(token),
          getUserTransactions(token)
        ]);
        setPlans(plansResponse.data);
        setTransactions(transactionsResponse.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);
  const totalPages = Math.ceil(transactions.length / transactionsPerPage);

  if (loading) {
    return (
      <Box
        p={8}
        maxW="auto"
        mx="auto"
        mt={20}
        boxShadow="lg"
        borderRadius="md"
        bg={bgColor}
      >
        <Heading as="h1" size="xl" mb={6} textAlign="center" color={textColor}>
          Loading...
        </Heading>
        <Center>
          <Spinner size="xl" />
        </Center>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        p={8}
        maxW="auto"
        mx="auto"
        mt={20}
        boxShadow="lg"
        borderRadius="md"
        bg={bgColor}
      >
        <Heading as="h1" size="xl" mb={6} textAlign="center" color={textColor}>
          Error
        </Heading>
        <Alert status="error">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  const renderSubscriptionCard = (subscriptions, title, isUpcoming = false) => (
    <Flex flexDir="column" h="100%">
      <Heading fontSize="lg" mb={4}>{title}</Heading>
      <Box p={5} shadow="md" borderWidth="1px" borderRadius="md" bg={bgColor} flex="1">
        {subscriptions.length > 0 ? (
          subscriptions.map(sub => (
            <Box key={sub.price_id} mb={4}>
              <Heading fontSize="lg">{sub.name}</Heading>
              <Text mt={2}>Amount: ${sub.name === "FOUNDER'S CLUB" ? 349 : sub.amount}</Text>
              {sub.name !== "FOUNDER'S CLUB" && <Text mt={2}>Status: {sub.is_current ? 'Current' : 'Upcoming'}</Text>}
              {isUpcoming && sub.starting_at && sub.name !== "FOUNDER'S CLUB" && <Text mt={2}>Start Date: {new Date(sub.starting_at).toLocaleDateString()}</Text>}
              {sub.is_current && sub.ending_at && !isUpcoming && sub.name !== "FOUNDER'S CLUB" && <Text mt={2}>Valid Till: {new Date(sub.ending_at).toLocaleDateString()}</Text>}
              <Tag size="sm" mt={2} colorScheme={sub.is_current ? 'green' : 'blue'}>
                <TagLabel>{sub.is_current ? 'Active' : 'Upcoming'}</TagLabel>
              </Tag>
            </Box>
          ))
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
  <Text>No plans found</Text>
</Box>

        )}
      </Box>
    </Flex>
  );

  const currentPlans = plans.filter(plan => plan.is_current || plan.name === "FOUNDER'S CLUB");
  const upcomingPlans = plans.filter(plan => (plan.starting_at && !plan.is_current) || (!plan.ending_at && plan.is_current));

  // Add the found plan if it's not already in currentPlans
  if (founder && !currentPlans.some(plan => plan.name === "FOUNDER'S CLUB")) {
    currentPlans.push({ name: "FOUNDER'S CLUB", amount: 349, is_current: true });
  }

  return (
    <Box
      p={8}
      maxW="auto"
      mx="auto"
      mt={20}
      boxShadow="lg"
      borderRadius="md"
      bg={bgColor}
    >
      <Heading as="h1" size="xl" mb={16} textAlign="center" color={textColor}>
        Subscriptions
      </Heading>

      {currentPlans.length === 0 ? (
        <Text textAlign="center" color={textColor} mb={6}>
          No Active Plans Found. <br/> Please Buy One Now.
        </Text>
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10} mb={10}>
          {renderSubscriptionCard(currentPlans, 'Current Plan')}
          {renderSubscriptionCard(upcomingPlans, 'Upcoming Plan', true)}
        </SimpleGrid>
      )}

      <Heading as="h1" size="xl" mb={6} textAlign="center" color={textColor}>
        Billing
      </Heading>
      {transactions.length > 0 ? (
        <>
          <Table variant="simple" colorScheme="teal">
            <Thead>
              <Tr>
                <Th>Amount</Th>
                <Th>Transaction ID</Th>
                <Th>Date</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentTransactions.map(transaction => (
                <Tr key={transaction.id}>
                  <Td>${transaction.amount.toFixed(2)}</Td>
                  <Td>{transaction.id}</Td>
                  <Td>{new Date(transaction.created).toLocaleDateString()}</Td>
                  <Td>
                    <Tag size="sm" colorScheme={transaction.status === 'succeeded' ? 'green' : 'red'}>
                      <TagLabel>{transaction.status}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {transactions.length > transactionsPerPage && (
            <Center mt={6}>
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                isDisabled={currentPage === 1}
                mr={2}
              >
                Previous
              </Button>
              <Text>{currentPage} of {totalPages}</Text>
              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                isDisabled={currentPage === totalPages}
                ml={2}
              >
                Next
              </Button>
            </Center>
          )}
        </>
      ) : (
        <Text textAlign="center" color={textColor} mt={6}>
          No Transactions Found
        </Text>
      )}
    </Box>
  );
};

export default Billing;
