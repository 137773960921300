import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../Service";
import axios from "axios";

// Chakra imports
import { Box, Flex } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import PricingBox from "./Components/PricingBox";

// Custom components
import DefaultAuth from "../../../layouts/auth/Default";
import { IoIosArrowRoundBack } from "react-icons/io";
import toast, { Toaster } from "react-hot-toast";

import { useUserContext } from "../../../contexts/UserContext";

function Plans() {
  // Chakra color mode
  const [plansList, setPlansList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const history = useHistory();
  const { free, founder, isSubscribed } = useUserContext();
  const [proratedAmount, setProratedAmount] = useState(0);

  const handleBuyOrUpgradeSubscription = async (stripePriceId, one_time, request_type) => {
    setButtonLoading(true);
    // Fetch the user's authentication token (adjust this to your implementation)
    const auth_token = window.localStorage.getItem("user-token");
    const authToken = "Token " + auth_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/checkout`,
        {
          price_id: stripePriceId,
          is_one_time: one_time,
          request_type: request_type,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );
      const updated = response.data.updated;
      if (updated) {
        setButtonLoading(false);
        toast.success("Subscription updated successfully.");
        window.location.reload();
      } else {
        setButtonLoading(false);
        toast.success("Checkout session created successfully.");
        window.location.href = response.data.url;
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error("Error creating checkout session.");
    }
  };

  const handleCancelSubscription = async () => {
    setButtonLoading(true);
    const auth_token = window.localStorage.getItem("user-token");
    const authToken = "Token " + auth_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/cancel-subscription`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );

      if (response.status === 200) {
        setButtonLoading(false);
        toast.success("Subscription cancelled successfully.");
        window.location.reload();
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error("Error cancelling subscription.");
    }
  };

  const handleDowngradeSubscription = async (price_id) => {
    setButtonLoading(true);
    const auth_token = window.localStorage.getItem("user-token");
    const authToken = "Token " + auth_token;

    try {
      const response = await axios.post(
        `${BASE_URL}/downgrade-subscription`,
        {
          price_id: price_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );

      if (response.status === 200) {
        setButtonLoading(false);
        toast.success("Subscription downgraded successfully.");
        window.location.reload();
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error("Error downgrading subscription.");
    }
  };

  const calculateProratedAmount = async (price_id) => {
    const auth_token = window.localStorage.getItem("user-token");
    const authToken = "Token " + auth_token;
    try {
      const response = await axios.post(
        `${BASE_URL}/calculate-amount`,
        {
          price_id: price_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken,
          },
        }
      );
      if (response.status === 200) {
        setProratedAmount(response.data.amount);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error calculating prorated amount.");
    }
  };

  const fetchPlans = () => {
    setLoading(true);
    const token = window.localStorage.getItem("user-token");
    axios
      .get(`${BASE_URL}/plans`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        response.data?.forEach((plan) => {
          if (plan.is_current) {
            setCurrentPlan(plan);
          }
        });
        setPlansList(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log("Error", error);
      });
  };

  useEffect(() => {
    fetchPlans();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <DefaultAuth>
        <Toaster position="top-right" reverseOrder={false} />
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="center"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
        >
          {loading ? (
            <Spinner size="xl" color="brand" />
          ) : (
            <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" gap="40px">
              <h1 style={{ textAlign: "center", fontSize: "3.2rem" }}>
                Take your customer experience to <br /> the next level with Botworx
              </h1>
              <Box display="flex" alignItems="center" gap="10px">
                {plansList.map((plan, index) => (
                  <PricingBox
                    key={index}
                    isSubscribed={isSubscribed}
                    currentPlan={currentPlan}
                    move={plansList?.some((item) => item.is_current && parseFloat(item.amount) > parseFloat(plan.amount)) || false}
                    popular={plan?.popular}
                    name={plan?.name}
                    price={parseFloat(plan.amount).toFixed(0)}
                    features={plan?.description?.split("#")}
                    rollover={plan?.rollover}
                    allowed_models={plan?.allowed_models}
                    web_bots={plan?.web_bots}
                    discord_bots={plan?.discord_bots}
                    api_bots={plan?.api_bots}
                    cw_control={plan?.cw_control}
                    ltm={plan?.ltm}
                    auraDS={plan?.auraDS}
                    pinecone={plan?.pinecone}
                    storage_space={plan?.storage_space}
                    storage_characters={plan?.storage_characters}
                    delete_inactive={plan?.delete_inactive}
                    founder={plan?.is_founder_plan}
                    credits={plan?.credits}
                    n_bots={plan?.n_bots}
                    is_founder={founder}
                    is_free={free}
                    is_current={plan?.is_current}
                    ending_at={plan?.ending_at}
                    starting_at={plan?.starting_at}
                    onSubscribe={() => {
                      if (plan?.is_founder_plan) {
                        handleBuyOrUpgradeSubscription(plan?.price_id, true, "founder");
                      } else {
                        handleBuyOrUpgradeSubscription(plan?.price_id, false, "subscription");
                      }
                    }}
                    onDowngrade={() => {
                      handleDowngradeSubscription(plan?.price_id);
                    }}
                    handleCancelSubscription={() => handleCancelSubscription}
                    buttonLoading={buttonLoading}
                    proratedAmount={proratedAmount}
                    calculateProratedAmount={() => calculateProratedAmount(plan?.price_id)}
                  />
                ))}
              </Box>
              <div
                onClick={() => {
                  history.push("/app/dashboard");
                }}
                style={{ cursor: "pointer", display: "flex", alignItems: "center", fontWeight: "bold" }}
              >
                <IoIosArrowRoundBack />
                &nbsp;Back
              </div>
            </Box>
          )}
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default Plans;
