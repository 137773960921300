import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  InputGroup,
  InputLeftElement,
  Spinner,
  Button,
  Flex,
  useDisclosure,
  Text,
  Image,
  IconButton,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { getAllUsersForBot, getChats } from "../../../../Service";
import { useBotContext } from "../../../../contexts/BotContext";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";

const ConversationHistory = () => {
  const token = localStorage.getItem("user-token");
  const { botId } = useBotContext();
  const [nameFilter, setNameFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const itemsPerPage = 7;

  const filteredUsers = allUsers?.filter((user) => {
    return nameFilter === "" || user?.name?.toLowerCase().includes(nameFilter?.toLowerCase());
  });

  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentItems = filteredUsers.slice(offset, offset + itemsPerPage);

  const truncateFileName = (fileName, maxLength = 15) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    const name = lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;
    const extension = lastDotIndex !== -1 ? fileName.slice(lastDotIndex) : '';

    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...${extension}`;
    }
    return fileName;
  };

  const fetchUserChatHistory = (userId) => {
    onOpen();
    setChatLoading(true);
    getChats(botId, userId, token)
      .then((res) => {
        setChats(
          res.data.history.map((message) => ({
            text: message.text,
            isBot: message.is_bot,
            isSent: !message.is_bot,
            is_voice: message.is_voice,
            voice_path: message.voice_path,
            image_path: message.image_path,
            document_path: message.document_path,
          }))
        );
        setChatLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setChatLoading(false);
      });
  };

  const fetchAllUsers = () => {
    setLoading(true);
    getAllUsersForBot(token, botId)
      .then((res) => {
        const users = res.data.users.filter((user) => user[0] && user[1]).map((user) => ({ id: user[0], name: user[1] }));
        setAllUsers(users);
        setLoading(false);
      })
      .catch((err) => console.log(err), setLoading(false));
  };

  useEffect(() => {
    fetchAllUsers();
  }, []);

  return (
    <>
      {loading ? (
        <Spinner label="loading..." />
      ) : (
        <VStack spacing={4}>
          <Box width={"100%"}>
            <InputGroup mb={4}>
              <InputLeftElement pointerEvents="none" children={<SearchIcon color="gray.300" />} />
              <Input
                name="name__filter"
                type="text"
                placeholder="Filter by name"
                value={nameFilter}
                onChange={(e) => setNameFilter(e.target.value)}
              />
            </InputGroup>
            <TableContainer h={"40vh"} overflowY={"auto"}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>User</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {currentItems.map((user, index) => (
                    <Tr
                      onClick={() => fetchUserChatHistory(user.id)}
                      cursor={"pointer"}
                      transition={"all 0.5s ease-in-out"}
                      _hover={{ background: "purple.100" }}
                      key={index}
                    >
                      <Td>{user.name}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
          <Flex gap="20px">
            {currentPage > 0 && <Button onClick={() => setCurrentPage((prev) => prev - 1)}>{"<"}</Button>}
            <Button>{currentPage + 1}</Button>
            {currentPage + 1 < pageCount && <Button onClick={() => setCurrentPage((prev) => prev + 1)}>{">"}</Button>}
          </Flex>
        </VStack>
      )}

      <Modal onClose={onClose} isOpen={isOpen} scrollBehavior={"inside"} isCentered blockScrollOnMount={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Conversation history</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {chatLoading ? (
              <Spinner />
            ) : chats.length === 0 ? (
              <p>No conversations found</p>
            ) : (
              <VStack>
                {chats?.map((message, index) => {
                  return (
                    <Box
                      key={index}
                      maxWidth="70%"
                      height="auto"
                      margin="8px"
                      padding={message.is_voice || message.image_path || message.document_path ? "0px" : "12px"}
                      border={message.is_voice || message.image_path || message.document_path ? "none" : "1px solid rgba(0,0,0,0.1)"}
                      backgroundColor={
                        message.isSent && !message?.is_voice
                          ? "#fff"
                          : message.isBot || message.isSent
                            ? message?.is_voice || message.image_path || message.document_path
                              ? "transparent"
                              : "#412AFB"
                            : "lightgrey"
                      }
                      color={message.isSent ? "#000" : message.isBot ? "#fff" : "#000"}
                      borderRadius={
                        message.isSent ? "12px 0 12px 12px" : message.isBot ? (message?.is_voice || message.document_path ? "0px" : "0 12px 12px 12px") : "12px 12px 12px 0"
                      }
                      wordWrap="break-word"
                      alignSelf={message.isSent ? "flex-end" : "flex-start"}
                    >
                      {message?.document_path ? (
                        message.document_path.endsWith('.pdf') ? (
                          <Box
                            position="relative"
                            bg="white"
                            borderRadius="md"
                            boxShadow="md"
                            padding="10px"
                            margin="8px auto" // Center the Box horizontally
                            display="flex"
                            flexDirection="column" // Stack elements vertically
                            alignItems="center" // Center items horizontally
                            justifyContent="flex-start" // Align to the start
                            marginLeft={message.isSent ? "auto" : "0"} // Align to the right for sent messages
                            marginRight={message.isSent ? "0" : "auto"} // Align to the left for received messages
                            transition="background 0.3s ease" // Add smooth transition effect for hover
                            _hover={{ bg: "gray.100" }} // Light background on hover
                          >
                            <Text
                              fontSize="md"
                              fontWeight="bold"
                              color="black"
                              mb="2" // Margin bottom for spacing
                            >
                              {truncateFileName(message?.document_path.split('/').pop())}
                            </Text>
                            <Box
                              width="100%"
                              height="150px" // Adjust height as needed
                              overflow="hidden" // Hide overflow content
                              marginBottom="10px" // Space between preview and buttons
                            >
                              <Box
                                as="iframe" // Use an iframe to display the PDF preview
                                src={message.document_path}
                                width="100%"
                                height="100%"
                                border="none"
                              />
                            </Box>
                            <Box display="flex" justifyContent="center" gap="2"> {/* Center icons */}
                              <IconButton
                                icon={<DownloadIcon color="black" />}
                                onClick={() => {
                                  fetch(message.document_path)
                                    .then(response => response.blob())
                                    .then(blob => {
                                      const url = window.URL.createObjectURL(blob);
                                      const a = document.createElement('a');
                                      a.style.display = 'none';
                                      a.href = url;
                                      a.download = message?.document_path.split('/').pop();
                                      document.body.appendChild(a);
                                      a.click();
                                      window.URL.revokeObjectURL(url);
                                    })
                                    .catch(() => alert('Failed to download file.'));
                                }}
                                aria-label="Download Document"
                                size="sm" // Smaller button size
                                bg="transparent"
                                _hover={{ bg: "gray.200" }} // Light background on hover
                              />
                              <IconButton
                                icon={<ViewIcon color="black" />}
                                onClick={() => {
                                  window.open(message.document_path, '_blank');
                                }}
                                aria-label="Preview Document"
                                size="sm" // Smaller button size
                                bg="transparent"
                                _hover={{ bg: "gray.200" }} // Light background on hover
                              />
                            </Box>
                          </Box>
                        ) : (
                          <Box
                            position="relative"
                            bg="white"
                            borderRadius="md"
                            boxShadow="md"
                            padding="10px"
                            margin="8px auto" // Center the Box horizontally
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between" // Ensure content is spaced evenly
                            marginLeft={message.isSent ? "auto" : "0"} // Align to the right for sent messages
                            marginRight={message.isSent ? "0" : "auto"} // Align to the left for received messages
                            transition="background 0.3s ease" // Add smooth transition effect for hover
                            _hover={{ bg: "gray.100" }} // Light background on hover
                          >
                            <Text
                              fontSize="md"
                              fontWeight="bold"
                              color="black"
                              flex="1"
                            >
                              {truncateFileName(message?.document_path.split('/').pop())}
                            </Text>
                            <Box display="flex" gap="2">
                              <IconButton
                                icon={<DownloadIcon color="black" />}
                                onClick={() => {
                                  fetch(message.document_path)
                                    .then(response => response.blob())
                                    .then(blob => {
                                      const url = window.URL.createObjectURL(blob);
                                      const a = document.createElement('a');
                                      a.style.display = 'none';
                                      a.href = url;
                                      a.download = message?.document_path.split('/').pop();
                                      document.body.appendChild(a);
                                      a.click();
                                      window.URL.revokeObjectURL(url);
                                    })
                                    .catch(() => alert('Failed to download file.'));
                                }}
                                aria-label="Download Document"
                                size="sm" // Smaller button size
                                bg="transparent"
                                _hover={{ bg: "gray.200" }} // Light background on hover
                              />
                              <IconButton
                                icon={<ViewIcon color="black" />}
                                onClick={() => {
                                  window.open(message.document_path, '_blank');
                                }}
                                aria-label="Preview Document"
                                size="sm" // Smaller button size
                                bg="transparent"
                                _hover={{ bg: "gray.200" }} // Light background on hover
                              />
                            </Box>
                          </Box>
                        )
                      ) : message?.image_path ? (
                        <Image
                          borderRadius="12px"
                          cursor="pointer"
                          boxSize="130px"
                          src={message.image_path}
                          fallbackSrc="https://s3.amazonaws.com/statics.botworx/media/images/aww-snap.gif"
                        />
                      ) : !message?.is_voice ? (
                        <Text
                          fontSize={{
                            base: "sm",
                          }}
                          fontWeight="400"
                        >
                          {message.text}
                        </Text>
                      ) : (
                        <audio autoPlay={message?.autoplay ? true : false} controls>
                          <source src={message.voice_path} type="audio/mpeg" />
                          Your browser does not support the audio element.
                        </audio>
                      )}




                    </Box>
                  );
                })}
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConversationHistory;
