import { Text } from "@chakra-ui/react";

const TextCard = (props) => {
  const convertLinksToHyperlinks = (text) => {
    const urlPattern = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const formattedText = text.replace(
      urlPattern,
      (url) => `<a href="${url}" style="text-decoration: underline;" target="_blank" rel="noopener noreferrer">${url}</a>`
    );
    return formattedText;
  };

  const { message } = props;
  return <Text fontSize="sm" fontWeight={400} dangerouslySetInnerHTML={{ __html: convertLinksToHyperlinks(message.text) }}></Text>;
};

export default TextCard;
