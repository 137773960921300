import { Box, Flex, Stack, Text, useColorModeValue, Icon, Spinner } from "@chakra-ui/react";
import { CiLogout } from "react-icons/ci";
import { useHistory } from "react-router-dom";
import { IoAlert } from "react-icons/io5";
import { useUserContext } from "../../../contexts/UserContext";
import Logo from '../../../assets/icons/Logo.svg';
import { FaEnvelope, FaCheckCircle } from 'react-icons/fa';
// Custom components
import Links from "../../../components/sidebar/components/Links";
import React, { useState, useEffect } from "react";
import { logout, user } from "../../../Service";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  let textColor = useColorModeValue("secondaryGray.500", "white");
  const [loading, setLoading] = useState(false);
  const { free, planName } = useUserContext(); 
  const history = useHistory();
  const [userEmail, setUserEmail] = useState('');

  const handleLogout = () => {
    const token = window.localStorage.getItem("user-token");
    setLoading(true);
    logout(token).then(() => {
      setLoading(false);
      window.localStorage.removeItem("user-token");
      history.push("/signin");
    }).catch(() => {
      setLoading(false);
      window.localStorage.removeItem("user-token");
      history.push("/signin");
    });
  }

  const fetchUserEmail = () => {
    const token = window.localStorage.getItem("user-token");
    if (token) {
      user(token).then((res) => {
        setUserEmail(res.data.email);
      }).catch((error) => {
        console.error("Error fetching user data:", error);
      });
    }
  }

  useEffect(() => {
    fetchUserEmail();
  }, []);

  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <div style={{ height: "100px", display: "flex", alignItems: "center" }}>
        <h1 style={{ 
          fontSize: '35px',
          fontWeight: 'bolder',
          textAlign: 'start',
          marginBottom: '20px',         
          marginLeft: '30px',
        }}>
          <img src={Logo} alt="logo" style={{ height: "60px", marginRight: "10px" }} />
          {/* botworx<span style={{ color: "#7551FF" }}>.</span>io */}
        </h1>
      </div>
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes.filter(route => route.name === 'Dashboard')} />
          <Links routes={routes.filter(route => route.name === 'Training Document')} />
          <div style={{ display: "flex", marginLeft: "10px", marginTop: "8px", cursor: "pointer" }}>
            {loading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Spinner />
                <Text
                  fontSize={"md"}
                  color={textColor}
                  fontWeight='normal'
                  ps={{ sm: "10px", xl: "16px" }}
                  pt='5px'
                >
                  Logging out...
                </Text>
              </div>
            ) : (
              <div style={{ display: "flex" }} onClick={handleLogout}>
                <Icon as={CiLogout} width='20px' height='20px' color='red' mt={1} mr={1} />
                <Text
                  fontSize={"md"}
                  color="red"
                  fontWeight='normal'
                  ps={{ sm: "10px", xl: "16px" }}
                  pt='5px'
                >
                  Log out
                </Text>
              </div>
            )}
          </div>
        </Box>
      </Stack>
      <Box mb="3px" ps='20px' pe={{ md: "5px", "2xl": "1px" }}>
        <Links routes={routes.filter(route => route.name === 'Profile')} />
      </Box>
      <Box mb="50px" ps='20px'>
        <Links routes={routes.filter(route => route.name === 'Billing')} />
      </Box>
      <Box mb="50px" bg="none" border="2px solid black" borderRadius="10px" width="250px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" p="10px">
      <Box display="flex" alignItems="center" mb="8px">
    <FaEnvelope style={{ marginRight: '8px' }} />
    <Text fontSize="md" color="grey" fontWeight="normal">
      {userEmail}
    </Text>
  </Box>
  <Box display="flex" alignItems="center">
    <FaCheckCircle style={{ marginRight: '8px' }} />
    <Text fontSize="md" color="grey" fontWeight="bold">
      {planName || 'No plan selected'}
    </Text>
  </Box>
</Box>
      {free ? (
        <Box
          width="100%"
          height="50px"
          background="#3311db"
          mb={7}
          ml={-2}
          borderRadius="10px"
          cursor="pointer"
          onClick={() => history.push('/plans')}
        >
          <Flex align="center" justify="center" height="100%" fontWeight="bold">
            <Icon as={IoAlert} color="red" fontSize="lg" />
            <Text color="white" fontSize="sm" fontWeight="bold">
              Upgrade your plan
            </Text>
          </Flex>
        </Box>
      ) : null}
    </Flex>
  );
}

export default SidebarContent;
