import { BASE_URL } from "../../../Service";

export class Chatbot {
    constructor(bot_id, api_key, session_id = null) {
      this.BOT_ID = bot_id;
      this.API_KEY = api_key;
      this.SESSION_ID = session_id;
      this.userMessage = null;
      this.inputInitHeight = null;
      this.user_email = null;
      this.user_name = null;
      this.user_id = null;
      this.botColor = null;
      this.botName = null;
      this.botImage = null;
      this.apiURL = BASE_URL;
    }
  
    init() {
      this.cacheDOMElements();
      this.setupEventListeners();
    }
  
    cacheDOMElements() {
      // Cache your DOM elements here
      this.chatbotToggler = document.querySelector(".chatbot-toggler-CLdJCFCExx");
      this.closeBtn = document.querySelector(".close-btn");
      this.closeBtN = document.querySelector(".closeBtn");
      this.chatbox = document.querySelector(".chatbox");
      this.chatInput = document.querySelector(".chat-input input");
      this.sendChatBtn = document.querySelector(".chat-input span");
      this.submitInformationBtn = document.getElementById("submit-info-btn");
      this.nameOFUser = document.getElementById("inf-AKJDNJK-name");
      this.emailOFUser = document.getElementById("inf-AKJDNJK-email");
      this.companyOFUser = document.getElementById("inf-AKJDNJK-company");
      this.chatboxContainer = document.getElementById("chatbox_KJsdaASNK");
      this.informationContainer = document.getElementById("information_KJsdaASNK");
      this.predefinedQuestions = document.querySelectorAll("._____predefined__questions_____ div");
      this.closeTooltip = document.getElementById("chatbot-tooltip-CLdJCFCExx-cross");
      this.toolTip = document.querySelector(".chatbot-tooltip-CLdJCFCExx");
    }
  
    setupEventListeners() {
      this.chatInput.addEventListener("keydown", (e) => {
        if (e.key === "Enter" && !e.shiftKey && window.innerWidth > 800) {
          e.preventDefault();
          this.handleChat();
        }
      });
  
      this.sendChatBtn.addEventListener("click", () => {
        this.handleChat();
      });
  
      this.submitInformationBtn.addEventListener("click", () => {
        const name = this.nameOFUser.value.trim();
        const email = this.emailOFUser.value.trim();
        const company = this.companyOFUser.value.trim();
        if (!name || !email || !company) return;
  
        const visitorUrl = this.apiURL + `/bot/visitor/add/${this.BOT_ID}`;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            company: company,
            name: name,
            api_key: this.API_KEY,
          }),
        };
        document.getElementById("submit-info-btn").textContent = "Please wait...";
        fetch(visitorUrl, requestOptions)
          .then((res) => res.json())
          .then((data) => {
            document.getElementById("submit-info-btn").textContent = "Submit";
            this.chatboxContainer.style.display = "block";
            const width = window.innerWidth;
            if (width <= 490) {
              this.chatboxContainer.style.height = "100vh";
            }
            this.informationContainer.style.display = "none";
            this.user_name = email.replace(/@.*/, "_");
            this.user_email = email;
            this.chatbox.appendChild(this.createChatLi(`Hi ${name} 👋, How can I help you today?`, "incoming"));
            this.chatbox.scrollTo(0, this.chatbox.scrollHeight);
          })
          .catch(() => {
            console.log("Something went wrong");
            document.getElementById("submit-info-btn").textContent = "Submit";
          });
      });
  
      this.closeBtn.addEventListener("click", () => {
        document.body.classList.remove("show-chatbot");
      });
  
      this.closeBtN.addEventListener("click", () => {
        document.body.classList.remove("show-chatbot");
      });
  
      this.chatbotToggler.addEventListener("click", () => {
        this.toolTip.style.display = "none";
        document.body.classList.toggle("show-chatbot");
      });
  
      this.closeTooltip.addEventListener("click", () => {
        this.toolTip.style.display = "none";
      });
  
      this.predefinedQuestions.forEach((question) => {
        question.addEventListener("click", () => {
          this.chatInput.value = question.textContent;
          this.handleChat();
          document.querySelector("._____predefined__questions_____").style.display = "none";
        });
      });
    }
  
    createChatLi(message, className) {
      const chatLi = document.createElement("li");
      chatLi.classList.add("chat", `${className}`);
      let chatContent =
        className === "outgoing"
          ? `<p style="background: ${this.botColor}"></p>`
          : `<img src="${this.botImage}" alt="Not found" onerror="this.src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTD_4gnFout6SHVVbiK_gRmuH1gmccAm412BQ&s';" style="height: 35px; width: 35px; border-radius: 10px; margin-right: 4px;" /><p></p>`;
      chatLi.innerHTML = chatContent;
      chatLi.querySelector("p").innerHTML = message;
      return chatLi;
    }
  
    async sendUserInfo() {
      const visitorUrl = this.apiURL + `/bot/visitor/add/${this.BOT_ID}`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.user_email,
          name: this.user_name,
          api_key: this.API_KEY,
        }),
      };
      return await fetch(visitorUrl, requestOptions)
        .then(async (response) => {
          const user_token = (await response.json()).visitor_id;
          this.user_id = user_token;
          window.localStorage.setItem("user_token", user_token);
        })
        .catch((err) => console.log(err));
    }
  
    validate(input, type) {
      if (type === "name") {
        const regex = /^[A-Za-z\s]{1,23}$/;
        console.log(regex.test(input));
        return regex.test(input);
      } else {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(input);
      }
    }
  
    shakeInput() {
      const chatInput = document.getElementById("text_input");
      chatInput.classList.add("shake");
      setTimeout(() => {
        chatInput.classList.remove("shake");
      }, 300);
    }
  
    generateResponse(chatElement) {
      const messageElement = chatElement.querySelector("p");
  
      if (this.user_name == null) {
        this.user_name = this.userMessage;
        messageElement.innerHTML = `Thanks ${this.user_name.split(" ")[0]}! Nice to meet you. You can call me ${this.botName}. Now, what is a good email for you?`;
        if (!this.user_email) {
          document.getElementById("text_input").placeholder = "Type your email address..";
        }
        this.chatbox.scrollTo(0, this.chatbox.scrollHeight);
        return;
      }
  
      if (this.user_email == null) {
        this.user_email = this.userMessage;
        this.sendUserInfo().then(() => {
          messageElement.innerHTML = `Excellent. Be sure to let me know when you’re done chatting by closing this window or clicking “end chat”. Now, how can I help you today?`;
          document.getElementById("text_input").placeholder = "Enter your message...";
        });
        this.chatbox.scrollTo(0, this.chatbox.scrollHeight);
        return;
      }
  
      const formData = new FormData();
      const payload = {
        api_key: this.API_KEY,
        bot_id: this.BOT_ID,
        message: this.userMessage,
        is_voice: false,
        image_request: false,
        username: this.user_name,
        user_id: this.user_id,
        session_id: this.SESSION_ID,
      };
  
      formData.append("data", JSON.stringify(payload));
  
      fetch(this.apiURL + "/chat", {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          messageElement.innerHTML = data.message;
        })
        .catch(() => {
          messageElement.classList.add("error");
          messageElement.textContent = "Oops! Something went wrong. Please try again.";
        })
        .finally(() => this.chatbox.scrollTo(0, this.chatbox.scrollHeight));
    }
  
    handleChat() {
      this.userMessage = this.chatInput.value.trim();
      if (!this.userMessage) return;
      if (!this.user_name) {
        if (!this.validate(this.userMessage, "name")) {
          this.shakeInput();
          return;
        }
      } else if (!this.user_email) {
        if (!this.validate(this.userMessage, "email")) {
          this.shakeInput();
          return;
        }
      }
  
      this.chatInput.value = "";
  
      this.chatbox.appendChild(this.createChatLi(this.userMessage, "outgoing"));
      this.chatbox.scrollTo(0, this.chatbox.scrollHeight);
  
      setTimeout(() => {
        const incomingChatLi = this.createChatLi(
          `<div id="wave">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
    </div>`,
          "incoming"
        );
        this.chatbox.appendChild(incomingChatLi);
        this.chatbox.scrollTo(0, this.chatbox.scrollHeight);
        this.generateResponse(incomingChatLi);
      }, 600);
    }
  
    fetchHTML(url, callback) {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((html) => {
          callback(html);
        })
        .catch((error) => {
          console.error("Fetch error:", error);
        });
    }
  
    addCSS() {
      var style = document.createElement("style");
      style.type = "text/css";
      style.innerHTML = `
          /* Import Google font - Poppins */
          @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
          @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0&display=swap");
          @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0&display=swap");
          
          * {
              margin: 0;
              padding: 0;
              box-sizing: border-box;
              font-family: "Poppins", sans-serif;
          }
  
          .chatbot-toggler-CLdJCFCExx {
            z-index: 9999999999999;
            position: fixed;
            bottom: 30px;
            right: 35px;
            outline: none;
            border: none;
            height: 60px;
            width: 60px;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: all 0.2s ease;
            box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
              0 32px 64px -48px rgba(0, 0, 0, 0.5);
          }
          .chatbot-tooltip-CLdJCFCExx{
            padding: 10px 25px;
            z-index: 9999999999999;
            position: fixed;
            bottom: 100px;
            right: 35px;
            color: #fff;
            border-radius: 10px;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          }
  
          .chatbot-tooltip-CLdJCFCExx .material-symbols-outlined{
            position: absolute;
            top: -15px;
            right: -10px;
            border-radius: 50%;
            font-size: 20px;
            padding: 5px;
            cursor: pointer;
            box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
          }
  
          .chatbot-tooltip-CLdJCFCExx .chatbot-tooltip-CLdJCFCExx-span{
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-weight: 500;
          }
  
          body.show-chatbot .chatbot-toggler-CLdJCFCExx {
            transform: rotate(90deg);
          }
          .chatbot-toggler-CLdJCFCExx span {
            color: #fff;
            position: absolute;
          }
          .chatbot-toggler-CLdJCFCExx span:last-child,
          body.show-chatbot .chatbot-toggler-CLdJCFCExx span:first-child {
            opacity: 0;
          }
          body.show-chatbot .chatbot-toggler-CLdJCFCExx span:last-child {
            opacity: 1;
          }
          .chatbot-CLdJCFCExx {
            z-index: 9999999999999;
            position: fixed;
            right: 35px;
            bottom: 100px;
            width: 400px;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            opacity: 0;
            pointer-events: none;
            transform: scale(0.5);
            transform-origin: bottom right;
            box-shadow: 0 0 128px 0 rgba(0, 0, 0, 0.1),
              0 32px 64px -48px rgba(0, 0, 0, 0.5);
            transition: all 0.1s ease;
          }
          body.show-chatbot .chatbot-CLdJCFCExx {
            opacity: 1;
            pointer-events: auto;
            transform: scale(1);
          }
          .chatbot-CLdJCFCExx header {
            padding: 16px 0;
            position: relative;
            text-align: center;
            height: 90px;
            color: #fff;
            background: #34495E;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          }
          .chatbot-CLdJCFCExx .chatbox {
            overflow-y: auto;
            height: 500px;
            padding: 30px 20px 100px;
            padding-bottom: 8rem;
          }
          .chatbot-CLdJCFCExx :where(.chatbox, input)::-webkit-scrollbar {
            width: 6px;
          }
          .chatbot-CLdJCFCExx :where(.chatbox, input)::-webkit-scrollbar-track {
            background: #fff;
            border-radius: 25px;
          }
          .chatbot-CLdJCFCExx :where(.chatbox, input)::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 25px;
          }
          .chatbox .chat {
            display: flex;
            list-style: none;
          }
          .chatbox .outgoing {
            margin: 20px 0;
            justify-content: flex-end;
          }
          .chatbox .incoming span {
            width: 32px;
            height: 32px;
            color: #fff;
            cursor: default;
            text-align: center;
            line-height: 32px;
            align-self: flex-end;
            border-radius: 5px;
            margin: 0 10px 7px 0;
          }
          .chatbox .chat p {
            white-space: pre-wrap;
            padding: 12px 16px;
            border-radius: 10px 10px 0 10px;
            max-width: 75%;
            color: #fff;
            font-size: 0.95rem;
            background: #34495E;
          }
          .chatbox .incoming p {
            border-radius: 10px 10px 10px 0;
          }
          .chatbox .chat p.error {
            color: #721c24;
            background: #f8d7da;
          }
          .chatbox .incoming p {
            color: #000;
            background: #f2f2f2;
          }
          .chatbot-CLdJCFCExx .chat-input {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 13px;
            width: 100%;
            height: 60px;
            background: #fff;
            padding: 3px 20px;
            border-top: 1px solid #ddd;
            font-family: "Poppins", sans-serif;
          }
          .chat-input input {
            height: 50px;
            width: 100%;
            border: none;
            outline: none;
            overflow: hidden;
          }
          
          div#wave {
            display: flex;
            height: 20px;
            position: relative;
            margin-top: 1vh;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
         }
          div#wave .dot {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 3px;
            background: #fff;
            box-shadow: rgba(99, 99, 99, 0.09) 0px 2px 8px 0px;
            animation: wave 1.3s linear infinite;
         }
          div#wave .dot:nth-child(2) {
            animation-delay: -1.1s;
         }
          div#wave .dot:nth-child(3) {
            animation-delay: -0.9s;
         }
          @keyframes wave {
            0%, 60%, 100% {
              transform: initial;
           }
            30% {
              transform: translateY(-15px);
           }
         }
  
          @media (max-width: 490px) {
            .chatbot-toggler-CLdJCFCExx {
              right: 20px;
              bottom: 20px;
            }
            .chatbot-CLdJCFCExx {
              right: 0;
              bottom: 0;
              height: 100%;
              border-radius: 0;
              width: 100%;
            }
            .chatbot-CLdJCFCExx .chatbox {
              height: 80%;
              padding: 25px 15px 100px;
            }
            .chatbot-CLdJCFCExx .chat-input {
              padding: 5px 15px;
            }
            .chatbot-CLdJCFCExx header span {
              display: block;
            }
          }
          ._____predefined__questions_____{
            position: absolute;
            bottom: 60px;
            right: 0;
            left: 0;
            display: flex;
            flex-direction: row;
            padding: 0px 10px;
            overflow: auto;
            background: #fff;
            gap: 5px;
          }
          ._____predefined__questions_____ div{
            font-size: 12px;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            padding: 5px 5px;
            margin-bottom: 5px;
            cursor: pointer !important;
          }
  
          /* Shake animation */
          @keyframes shake {
              0% { transform: translateX(0); }
              25% { transform: translateX(-5px); }
              50% { transform: translateX(5px); }
              75% { transform: translateX(-5px); }
              100% { transform: translateX(0); }
          }
  
          /* Apply shake animation to the input box */
          .shake {
              animation: shake 0.3s;
              border-color: red;
          }
          `;
      document.head.appendChild(style);
    }
  
    async loadChatHistory() {
      try {
        const response = await fetch(this.apiURL + `/bot/chats/user`, {
          method: "POST",
          body: JSON.stringify({
            api_key: this.API_KEY,
            bot_id: this.BOT_ID,
            username: this.user_name,
            user_id: this.user_id,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
  
        return data?.history?.map((item) => ({
          message: item.message,
          type: item.is_bot ? "incoming" : "outgoing",
        }));
      } catch (e) {
        throw new Error();
      }
    }
  
    async authenticate(user_id) {
      try {
        const response = await fetch(this.apiURL + `/bot/visitor/${this.BOT_ID}/${user_id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const data = await response.json();
          this.user_name = data.name;
          this.user_id = data.visitor_id;
          this.user_email = data.email;
          const chatHistory = await this.loadChatHistory();
          return chatHistory.reverse();
        } else {
          return []
        }
      } catch (err) {
        return [];
      }
    }
    async start() {
      let history = [
        {
          message: "Hello! Thanks for visiting. Before we get started, What is your name? (First and Last Name).",
          type: "incoming",
        },
      ];
  
      const user_id = window.localStorage.getItem("user_token");
      let chat_history = [];
      if (user_id) {
        // fetch alredy loggged in user info/
        chat_history = await this.authenticate(user_id);
      }
  
      fetch(this.apiURL + "/bot/name/" + this.BOT_ID + `?api_key=${this.API_KEY}&session_id=${this.SESSION_ID}`)
        .then((response) => {
          // Check if the response status is OK (200)
          if (response.ok) {
            // Parse the JSON response
            return response.json();
          } else {
            throw new Error(`Failed to fetch data. Status: ${response.status}`);
          }
        })
        .then((data) => {
          this.addCSS();
          this.botName = data.name;
          this.botImage = data.image;
          this.botColor = data.color;
          const phone_number = data.phone_number;
          const bot_mail = data.bot_mail;
          const schedule_link = data.schedule_link;
          const tooltip = data.tooltip;
          const questions = [];
          if(data.question_one) {
            questions.push(data.question_one);
          }
          if (data.question_two) {
            questions.push(data.question_two);
          }
          if(data.question_three) {
            questions.push(data.question_three);
          }
          const tagline = data.tagline === null ? "How can I help you today?" : data.tagline;
  
          const html = `
                <div class="chatbot-tooltip-CLdJCFCExx" style="background: ${this.botColor}">
                  <span class="material-symbols-outlined" id="chatbot-tooltip-CLdJCFCExx-cross" style="color: ${
                    this.botColor
                  }; background: #fff">close</span>
                  <span class="chatbot-tooltip-CLdJCFCExx-span">
                    <span style="font-size: 30px;">👋&nbsp;</span><span>${tooltip}</span>
                  </span>
                </div>
                <button class="chatbot-toggler-CLdJCFCExx" style="background: ${this.botColor}">
                  <span class="material-symbols-rounded" style="font-size: 28px;">
                    <img src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMwIiB3aWR0aD0iMzUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxmaWx0ZXIgaWQ9ImEiIGhlaWdodD0iMTM4LjclIiB3aWR0aD0iMTMxLjQlIiB4PSItMTUuNyUiIHk9Ii0xNS4xJSI+PGZlTW9ycGhvbG9neSBpbj0iU291cmNlQWxwaGEiIG9wZXJhdG9yPSJkaWxhdGUiIHJhZGl1cz0iMSIgcmVzdWx0PSJzaGFkb3dTcHJlYWRPdXRlcjEiLz48ZmVPZmZzZXQgZHk9IjEiIGluPSJzaGFkb3dTcHJlYWRPdXRlcjEiIHJlc3VsdD0ic2hhZG93T2Zmc2V0T3V0ZXIxIi8+PGZlR2F1c3NpYW5CbHVyIGluPSJzaGFkb3dPZmZzZXRPdXRlcjEiIHJlc3VsdD0ic2hhZG93Qmx1ck91dGVyMSIgc3RkRGV2aWF0aW9uPSIxIi8+PGZlQ29tcG9zaXRlIGluPSJzaGFkb3dCbHVyT3V0ZXIxIiBpbjI9IlNvdXJjZUFscGhhIiBvcGVyYXRvcj0ib3V0IiByZXN1bHQ9InNoYWRvd0JsdXJPdXRlcjEiLz48ZmVDb2xvck1hdHJpeCBpbj0ic2hhZG93Qmx1ck91dGVyMSIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjA3IDAiLz48L2ZpbHRlcj48cGF0aCBpZD0iYiIgZD0iTTE0LjIzIDIwLjQ2bC05LjY1IDEuMUwzIDUuMTIgMzAuMDcgMmwxLjU4IDE2LjQ2LTkuMzcgMS4wNy0zLjUgNS43Mi00LjU1LTQuOHoiLz48L2RlZnM+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48dXNlIGZpbGw9IiMwMDAiIGZpbHRlcj0idXJsKCNhKSIgeGxpbms6aHJlZj0iI2IiLz48dXNlIGZpbGw9IiNmZmYiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIiB4bGluazpocmVmPSIjYiIvPjwvZz48L3N2Zz4=">
                  </span>
                  <span class="material-symbols-outlined" style="font-size: 28px;">close</span>
                </button>
                <div class="chatbot-CLdJCFCExx" style="font-family: 'Poppins', sans-serif;">
                  <main class="user__information-2342353232" id="information_KJsdaASNK" style="display: none">
                    <header style="background: ${this.botColor}">
                      <div style="display: flex; justify-content: start; align-items: center;">
                
                        <div style="display: flex; margin-left: 20px; flex-direction: column; align-items: start;">
                          <span
                          style="
                            font-family: 'Poppins', sans-serif;
                            font-size: 20px;
                            font-weight: bolder;
                          "
                          id="bot_name_two"
                          ></span
                        >
                        <span
                          style="
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            margin-top: 2px;
                            font-weight: normal;
                          "
                          >Please input your information.</span
                        >
                        </div>
                      </div>
                      <span
                        class="close-btn material-symbols-outlined"
                        style="position: absolute; top: 10px; right: 10px; cursor: pointer"
                        >close</span
                      >
                    </header>
                
                    <div class="user__information-inputs-345829362" style="padding: 20px;">
                      <input type="text" id="inf-AKJDNJK-name" placeholder="Name" style="height: 50px;
                        padding: 0px 20px;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        width: 100%;
                        margin-bottom: 10px;
                        box-shadow: none;
                        outline: none;"/>
  
                        <input type="text" id="inf-AKJDNJK-company" placeholder="Company" style="height: 50px;
                        padding: 0px 20px;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        width: 100%; 
                        margin-bottom: 10px;
                        box-shadow: none;
                        outline: none;"/>
                        
                        <input type="email" id="inf-AKJDNJK-email" placeholder="Email" style="height: 50px;
                        padding: 0px 20px;
                        border: 1px solid rgba(0, 0, 0, 0.2);
                        border-radius: 10px;
                        width: 100%; 
                        margin-bottom: 30px;
                        box-shadow: none;
                        outline: none;"/>
                      <div id="submit-info-btn" style="display: flex; align-items: center; justify-content: center; cursor: pointer;background: ${
                        this.botColor
                      }; color: #fff; cursor: pointer; width: 100%; border: none; height: 50px; border-radius: 10px;">Submit</div>
                    </div>
                  </main>
                  <main style="display: block;" id="chatbox_KJsdaASNK">
                  <header style="background: ${this.botColor}">
                    <div style="display: flex; justify-content: start; align-items: center;">
                
                      <div style="display: flex; margin-left: 20px; flex-direction: column; align-items: start;">
                        <span
                        style="
                          font-family: 'Poppins', sans-serif;
                          font-size: 20px;
                          font-weight: bolder;
                        "
                        id="bot_name_one"
                        ></span
                      >
                      <span
                        style="
                          font-family: 'Poppins', sans-serif;
                          font-size: 14px;
                          margin-top: 2px;
                          font-weight: normal;
                        "
                        >${tagline}</span
                      >
                      </div>
                    </div>
                    <div style="display: flex; align-items: center; gap: 5px; position: absolute; bottom: 10px; right: 10px;">
                    ${
                      bot_mail === null
                        ? ""
                        : `<span class="material-symbols-outlined" style="cursor: pointer; border-radius: 5px; background: #fff; color: ${this.botColor}; font-size: 20px; padding: 3px;" onclick="window.open('mailto:${bot_mail}', '_target')">email</span>`
                    }
                              ${
                                phone_number === null
                                  ? ""
                                  : `<span class="material-symbols-outlined" style="cursor: pointer; border-radius: 5px; background: #fff; color: ${this.botColor}; font-size: 20px; padding: 3px;" onclick="window.open('tel:${phone_number}', '_target')">phone</span>`
                              }
                              ${
                                schedule_link === null
                                  ? ""
                                  : `<span class="material-symbols-outlined" style="cursor: pointer; border-radius: 5px; background: #fff; color: ${this.botColor}; font-size: 20px; padding: 3px;" onclick="window.open('${schedule_link}', '_target')">schedule</span>`
                              }
                    </div>
                    <span
                      class="close-btn closeBtn material-symbols-outlined"
                      style="position: absolute; top: 10px; right: 10px; cursor: pointer"
                      >close</span
                    >
                  </header>
                  <ul class="chatbox">
  
                  </ul>
                  <div class="_____predefined__questions_____">
                    ${
                      questions.length === 0
                        ? ""
                        : questions.length === 1
                        ? `<div style='cursor: pointer; background: ${this.botColor}; color: #fff;'>` + questions[0] + "</div>"
                        : questions.length === 2
                        ? `<div style='cursor: pointer; background: ${this.botColor}; color: #fff;'>` +
                          questions[0] +
                          `</div><div style='cursor: pointer; background: ${this.botColor}; color: #fff;'>` +
                          questions[1] +
                          "</div>"
                        : `<div style='cursor: pointer; background: ${this.botColor}; color: #fff;'>` +
                          questions[0] +
                          `</div><div style='cursor: pointer; background: ${this.botColor}; color: #fff;'>` +
                          questions[1] +
                          `</div><div style='cursor: pointer; background: ${this.botColor}; color: #fff;'>` +
                          questions[2] +
                          "</div>"
                    }
                  </div>
                  <div class="chat-input" style="font-family: 'Poppins', sans-serif">
                    <input placeholder="Enter a message..." type="text" id="text_input" style="font-family: 'Poppins', sans-serif; height: 50px;" required />
                    <span id="send-btn" class="material-symbols-rounded" style="color: ${
                      this.botColor
                    }; width: 50px; height: 50px; display: flex; align-items: center; justify-content: center; cursor: pointer;">send</span>
                    
                  </div>
                  <div style="display: flex; align-items: center; justify-content: center; width: 100%; position: absolute; bottom: 0px; background: white;">
                    <p style="font-size: 12px;">Powered by <strong>Botworx</strong></p>
                  </div>
                </main>
                </div>
                  `;
  
          const div = document.createElement("div");
          div.innerHTML = html;
          document.body.appendChild(div);
  
          // Now that the HTML content is available, you can initialize the chatbot.
          this.cacheDOMElements(); // Move this line here.
          this.setupEventListeners(); // Move this line here.
  
          document.getElementById("bot_name_one").textContent = this.botName;
          document.getElementById("bot_name_two").textContent = this.botName;
  
          if (user_id && this.user_name && this.user_email) {
            // fetch alredy loggged in user info/
            history = [
              ...history,
              { message: this.user_name, type: "outgoing" },
              {
                message: `Thanks ${this.user_name.split(" ")[0]}! Nice to meet you. You can call me ${this.botName}. Now, what is a good email for you?`,
                type: "incoming",
              },
              { message: this.user_email, type: "outgoing" },
              {
                message:
                  "Excellent. Be sure to let me know when you’re done chatting by closing this window or clicking “end chat”. Now, how can I help you today?",
                type: "incoming",
              },
              ...chat_history,
            ];
          }
  
          history.forEach((item) => {
            this.chatbox?.appendChild(this.createChatLi(item?.message, item?.type));
          });
  
          if (!this.user_name) {
            document.getElementById("text_input").placeholder = "Type your name here...";
          }
  
          this.chatbox.scrollTo(0, this.chatbox.scrollHeight);
        })
        .catch((error) => {
          alert(error);
          console.error("Error:", error);
        });
    }
  }



