import React, { useState } from "react";
import { Icon } from "@chakra-ui/react";
import { css } from "@emotion/react";
import { TbReload } from "react-icons/tb";
import { keyframes } from "@chakra-ui/react";

export const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const SpinningIconComponent = ({ url, addURL }) => {
  const [spinner, setSpinner] = useState(false);

  const handleClick = async () => {
    setSpinner(true);
    await addURL(true, url.url, "").then(() => setSpinner(false));
  };

  return (
    <Icon
      onClick={handleClick}
      css={
        spinner
          ? css`
              animation: ${spin} infinite 0.5s linear;
            `
          : ""
      }
      as={TbReload}
      w={5}
      h={6}
      color="grey"
      cursor="pointer"
    />
  );
};

export default SpinningIconComponent;
