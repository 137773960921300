import React, { useState, useRef, useEffect } from 'react';
import {
    Box,
    Button,
    Text,
    Input,
    FormControl,
    Flex,
    Icon
} from '@chakra-ui/react';
import { useBotContext } from '../../../../contexts/BotContext';
import { Spinner } from '@chakra-ui/react';
import { MdDeleteOutline } from "react-icons/md";
import { useColorModeValue } from '@chakra-ui/react';
import { getBotQuestions, updateTheBotName } from '../../../../Service';
import { updateTheBotColor } from '../../../../Service';
import { updateBotTagline } from '../../../../Service';
import { updateBotImage, getBotImage, delete_image } from '../../../../Service';

import toast from 'react-hot-toast';


function Styles() {
    const bgColor = useColorModeValue("#F4F7FD", "navy.900");
    const textColor = useColorModeValue("navy.700", "white");

    const token = window.localStorage.getItem("user-token");

    const { botId, botName, updateBotName, botColor, updateBotColor } = useBotContext()

    const [bottName, setBotName] = useState(botName)
    const [bottColor, setBotColor] = useState(botColor)
    const [tagline, setTagline] = useState()
    const [loading, updateLoader] = useState(false)
    const [image, setImage] = useState()
    const [loader, updateImageLoader] = useState(false)
    const fileInputRef = useRef(null);


    const updateName = () => {
        updateLoader(true)
        updateTheBotName({
            bot_name: bottName
        }, token, botId)
            .then((response) => {
                toast.success("Bot name updated successfully")
                updateBotName(response.data.name)
                setBotName(response.data.name)
                updateLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateLoader(false)
            })
    }

    const updateColor = () => {
        updateLoader(true)
        updateTheBotColor({
            bot_color: bottColor
        }, token, botId)
            .then((response) => {
                toast.success("Bot color updated successfully")
                updateBotColor(response.data.color)
                setBotColor(response.data.color)
                updateLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateLoader(false)
            })
    }

    const updateTagline = () => {
        updateLoader(true)
        updateBotTagline(token, botId, {
            tagline: tagline
        })
            .then((response) => {
                toast.success("Tagline updated successfully")
                updateLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateLoader(false)

            })
    }

    const deleteImage = () => {
        updateImageLoader(true)
        delete_image(token, botId)
            .then((response) => {
                toast.success("Image deleted successfully")
                setImage(null)
                updateImageLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateImageLoader(false)
            })
    }

    useEffect(() => {
        updateImageLoader(true)
        getBotImage(token, botId)
            .then((response) => {
                setImage(response.data.image)
                updateImageLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateImageLoader(false)
            })
        getBotQuestions(token, botId)
            .then((response) => {
                setTagline(response.data.tagline)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
            })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId])

    return (
        <>
            {loading ? <Box mt={5} style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column"
            }}>
                <Spinner />
                <Text fontSize="sm" mt={3} fontWeight="normal" color={textColor}>
                    Please Wait...
                </Text>
            </Box> : <Box my={5}>
                <FormControl width='100%' display='flex' justifyContent='center'>
                    <Box background={bgColor} boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" height="200px" width="200px" mb={5} cursor='pointer' display='flex' style={{
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative"
                    }}>
                        <Box position="absolute" top="4" right="4" background={bgColor} border="1px solid rgba(0, 0, 0, 0.1)" padding="1px 5px" borderRadius="50%" cursor="pointer" onClick={() => {
                            deleteImage()
                        }}>
                            <Icon as={MdDeleteOutline} color="red" cursor="pointer" />
                        </Box>
                        <input
                            style={{ display: "none" }}
                            type="file" ref={fileInputRef}
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => {
                                updateImageLoader(true)
                                updateBotImage(token, botId, {
                                    image: e.target.files[0]
                                })
                                    .then((response) => {
                                        toast.success("Bot image updated successfully")
                                        setImage(response.data.image)
                                        updateImageLoader(false)
                                    })
                                    .catch((error) => {
                                        if (error.response) {
                                            toast.error(error.response.data.message);
                                        }
                                        updateImageLoader(false)
                                    })
                            }} />
                        {loader ? <Spinner /> : image ? <img src={image} alt={botName} style={{ borderRadius: "50%", height: "100%", width: "100%", objectFit: 'cover' }} onClick={() => {
                            fileInputRef.current.click();
                        }} /> : <Text onClick={() => {
                            fileInputRef.current.click();
                        }}>
                            Select a bot image
                        </Text>}
                    </Box>
                </FormControl>
                <FormControl>
                    <Text mb={2}>Bot Name</Text>
                    <Flex>
                        <Input
                            _focus={{ boxShadow: "none" }}
                            color={textColor}
                            bg={bgColor}
                            style={{ borderRadius: "50px", height: '50px' }}
                            mr={2}
                            type="text"
                            placeholder="Bot Name"
                            value={bottName}
                            onChange={(e) => setBotName(e.target.value)}
                        />
                        <Button
                            fontSize='sm'
                            fontWeight='500'
                            w='20%'
                            h='50'
                            mb='24px'
                            onClick={() => {
                                updateName()
                            }}
                        >
                            Update
                        </Button>
                    </Flex>
                </FormControl>
                <FormControl>
                    <Text mb={2}>Bot Tagline</Text>
                    <Flex>
                        <Input
                            _focus={{ boxShadow: "none" }}
                            color={textColor}
                            bg={bgColor}
                            style={{ borderRadius: "50px", height: '50px' }}
                            mr={2}
                            type="text"
                            placeholder="Bot Tagline"
                            value={tagline}
                            onChange={(e) => setTagline(e.target.value)}
                        />
                        <Button
                            fontSize='sm'
                            fontWeight='500'
                            w='20%'
                            h='50'
                            mb='24px'
                            onClick={() => {
                                updateTagline()
                            }}
                        >
                            Update
                        </Button>
                    </Flex>
                </FormControl>
                <FormControl>
                    <Text mb={2}>Bot Color</Text>
                    <Flex>
                        <Input
                            _focus={{ boxShadow: "none" }}
                            color={textColor}
                            bg={bgColor}
                            style={{ borderRadius: "50px", height: '50px' }}
                            mr={2}
                            type="color"
                            placeholder="Bot Color"
                            value={bottColor}
                            onChange={(e) => setBotColor(e.target.value)}
                        />
                        <Button
                            fontSize='sm'
                            fontWeight='500'
                            w='20%'
                            h='50'
                            mb='24px'
                            onClick={() => {
                                updateColor()
                            }}
                        >
                            Update
                        </Button>
                    </Flex>
                </FormControl>
            </Box>}
        </>
    );
}

export default Styles;