import React from "react";

import { Icon } from "@chakra-ui/react";
import { MdHome } from "react-icons/md";
import { RiAccountPinCircleLine, RiBookLine, RiBillLine } from "react-icons/ri";

// Admin Imports
import Account from "../src/views/app/Account";
import Dashboard from "../src/views/app/Dashboard";
import TrainingDoc from "./views/app/Training";
import Billing from "./views/app/Billing";

const routes = [
  {
    name: "Dashboard",
    layout: "/app",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Dashboard,
  },
  {
    name: "Training Document",
    layout: "/app",
    path: "/training-doc",
    icon: <Icon as={RiBookLine} width='20px' height='20px' color='inherit' />,
    component: TrainingDoc,
  },
  {
    name: "Profile",
    layout: "/app",
    path: "/account",
    icon: (
      <Icon
        as={RiAccountPinCircleLine}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Account
  },
  {
    name: "Billing",
    layout: "/app",
    path: "/billing",
    icon: <Icon as={RiBillLine} width='20px' height='20px' color='inherit' />,
    component: Billing,
  }
];

export default routes;
