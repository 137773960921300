import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const user = (token) => {
  return axios.get(`${BASE_URL}/auth/user`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const forgot_password = (email) => {
  return axios.post(
    `${BASE_URL}/auth/forgot-password`,
    {
      email: email,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const change_password = (token, password) => {
  return axios.post(
    `${BASE_URL}/auth/change-password`,
    {
      new_password: password,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const checkSubscription = (token) => {
  return axios.get(`${BASE_URL}/check-subscription`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const register = (payload) => {
  return axios.post(`${BASE_URL}/auth/register`, payload, {
    "Content-Type": "application/json",
  });
};

export const login = (payload) => {
  return axios.post(`${BASE_URL}/auth/login`, payload, {
    "Content-Type": "application/json",
  });
};

export const logout = (token) => {
  return axios.post(
    `${BASE_URL}/auth/logout`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const getBots = (token) => {
  return axios.get(`${BASE_URL}/bots`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const chat = (payload, token) => {
  return axios.post(`${BASE_URL}/chat`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  });
};

export const clearChat = (botId, userId, token) => {
  return axios.get(`${BASE_URL}/bot/history/chat/delete/${botId}/${userId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const getChats = (botId, userId, token) => {
  return axios.get(`${BASE_URL}/bot/history/${botId}/${userId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateContext = (payload, token, id) => {
  return axios.put(`${BASE_URL}/bot/update/context/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const fetchContext = (token, id) => {
  return axios.get(`${BASE_URL}/bot/update/context/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateKnowledge = (payload, token, id) => {
  return axios.post(`${BASE_URL}/bot/update/knowledge/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const deleteKnowledge = (id, token) => {
  return axios.post(
    `${BASE_URL}/bot/delete/knowledge/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const fetchKnowledge = (token, id) => {
  return axios.get(`${BASE_URL}/bot/update/knowledge/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const fileUpload = (formData, token) => {
  return axios.post(`${BASE_URL}/upload`, formData, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const addBotURL = (payload, token) => {
  return axios.post(`${BASE_URL}/url/add`, payload, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const addBotDocsURL = (payload, token, botId) => {
  return axios.post(`${BASE_URL}/bot/google-docs-link/${botId}`, payload, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const deleteBotDocsURL = (token, botId) => {
  return axios.delete(`${BASE_URL}/bot/google-docs-link/${botId}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const getBotDocsURL = (botId, token) => {
  return axios.get(`${BASE_URL}/bot/google-docs-link/${botId}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const getBotFiles = (botId, token) => {
  return axios.get(`${BASE_URL}/files/${botId}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const getBotUrls = (botId, token) => {
  return axios.get(`${BASE_URL}/urls/${botId}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const updatePineconeDatabase = (payload, token, id) => {
  return axios.put(`${BASE_URL}/db/pinecone/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const fetchPineconeDatabase = (token, id) => {
  return axios.get(`${BASE_URL}/db/pinecone/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateDefaultDatabase = (token, id, payload) => {
  return axios.put(`${BASE_URL}/db/type/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateAuraDatabase = (token, id, payload) => {
  return axios.put(`${BASE_URL}/db/aurads/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const fetchAuraDatabase = (token, id) => {
  return axios.get(`${BASE_URL}/db/aurads/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const fetchBotAPIKey = (token) => {
  return axios.get(`${BASE_URL}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateBotAPIKey = (token, apiID) => {
  return axios.get(`${BASE_URL}/update/${apiID}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const saveBotDiscordToken = (token, payload, id) => {
  return axios.put(`${BASE_URL}/bot/discord/token/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const fetchBotDiscordToken = (token, id) => {
  return axios.get(`${BASE_URL}/bot/discord/token/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const startDiscordBot = (token, id) => {
  return axios.post(
    `${BASE_URL}/bot/discord/start/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const stopDiscordBot = (token, id) => {
  return axios.post(
    `${BASE_URL}/bot/discord/close/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const updateTheBotName = (payload, token, id) => {
  return axios.post(`${BASE_URL}/bot/name/update/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateTheBotColor = (payload, token, id) => {
  return axios.post(`${BASE_URL}/bot/color/update/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const getBotQuestions = (token, id) => {
  return axios.get(`${BASE_URL}/bot/general/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateBotQuestions = (token, id, payload) => {
  return axios.put(`${BASE_URL}/bot/general/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateBotTagline = (token, id, payload) => {
  return axios.put(`${BASE_URL}/bot/general/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const deleteBotFile = (token, botId, fileId) => {
  return axios.post(
    `${BASE_URL}/bot/delete/knowledge/file/${botId}/${fileId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const deleteBotURL = (token, botId, urlID) => {
  return axios.post(
    `${BASE_URL}/bot/delete/knowledge/url/${botId}/${urlID}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const createTheBot = (token, payload) => {
  return axios.post(`${BASE_URL}/bot/create`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const deleteTheBot = (token, botId) => {
  return axios.get(`${BASE_URL}/bot/delete/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const getBotContactInfo = (token, botId) => {
  return axios.get(`${BASE_URL}/bot/contact-info/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const getCreditsInfo = (token) => {
  return axios.get(`${BASE_URL}/credits`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateBotContactInfo = (token, botId, payload) => {
  return axios.put(`${BASE_URL}/bot/contact-info/${botId}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateCreditsInfo = (token, payload) => {
  return axios.put(`${BASE_URL}/credits`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateBotImage = (token, botId, payload) => {
  return axios.put(`${BASE_URL}/bot/image/${botId}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  });
};

export const getBotImage = (token, botId) => {
  return axios.get(`${BASE_URL}/bot/image/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const delete_image = (token, botId) => {
  return axios.get(`${BASE_URL}/bot/image/delete/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const getBotStatus = (token, botId) => {
  return axios.get(`${BASE_URL}/bot/status/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateBotStatus = (token, botId, status) => {
  return axios.post(
    `${BASE_URL}/bot/status/${botId}`,
    {
      status: status,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const getCreditPackages = (token) => {
  return axios.get(`${BASE_URL}/credit-packages`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const getIntegrations = (token, botId) => {
  return axios.get(`${BASE_URL}/bot/integrations/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const getContextModels = (token, botId) => {
  return axios.get(`${BASE_URL}/bot/context/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const updateContextWindow = (token, botId, id) => {
  return axios.post(
    `${BASE_URL}/bot/context_window/update/${botId}`,
    {
      context: id,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const updateModels = (token, botId, model) => {
  return axios.post(
    `${BASE_URL}/bot/models/update/${botId}`,
    {
      model: model,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    }
  );
};

export const uploadImages = (token, botId, payload) => {
  return axios.post(`${BASE_URL}/bot/image/upload/${botId}`, payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    },
  });
};

export const getUploadedImages = (token, botId) => {
  return axios.get(`${BASE_URL}/bot/image/upload/${botId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const deleteUploadedImage = (token, imageId) => {
  return axios.delete(`${BASE_URL}/bot/delete/image/upload/${imageId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const connectAndFetchVoices = (token, payload) => {
  return axios.post(`${BASE_URL}/elabs/connect`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const fetchNotificationEmails = (token, id) => {
  return axios.get(`${BASE_URL}/bot/notification/email/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const addNotificationEmail = (token, id, payload) => {
  return axios.post(`${BASE_URL}/bot/notification/email/${id}/add`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const removeNotificationEmail = (token, id, payload) => {
  return axios.delete(`${BASE_URL}/bot/notification/email/${id}/delete`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    data: payload,
  });
};

export const addVistor = (id, payload) => {
  return axios.post(`${BASE_URL}/bot/visitor/add/${id}`, payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const generateSession = (token, id) => {
  return axios.get(`${BASE_URL}/bot/session/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};

export const getAllUsersForBot = (token, id) => {
  return axios.get(`${BASE_URL}/bot/users/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};


export const getPlans = (token) => {
  return axios.get(`${BASE_URL}/plans`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};


export const getUserTransactions = (token) => {
  return axios.get(`${BASE_URL}/transactions`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  });
};