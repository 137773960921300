import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import '../src/assets/css/App.css';
import SignIn from '../src/views/auth/signIn';
import SignUp from '../src/views/auth/SignUp';
import AccountCreated from './views/auth/AccountCreated';
import Verified from './views/auth/Verified';
import Plans from '../src/views/app/Plans';
import AppLayout from '../src/layouts/app';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../src/theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { BotProvider } from '../src/contexts/BotContext';
import { UserProvider } from '../src/contexts/UserContext';
import { ModalProvider } from '../src/contexts/ModelContext';
import SubAuthGuard from '../src/views/SubAuth';
import AuthGuard from '../src/views/Auth';
import PreviewPage from './views/app/Preview';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<ThemeEditorProvider>
				<UserProvider>
					<BotProvider>
						<ModalProvider>
							<BrowserRouter>
								<Switch>
									<Route path={`/signin`} component={SignIn} />
									<Route path={`/signup`} component={SignUp} />
									<Route path={`/preview`} component={PreviewPage} />
									<Route path={`/plans`}>
										<SubAuthGuard>
											<Plans />
										</SubAuthGuard>
									</Route>
									<Route path={`/new-account`} component={AccountCreated} />
									<Route path={`/verified`} component={Verified} />
									<Route path="/app">
										<AuthGuard>
											<SubAuthGuard>
												<AppLayout />
											</SubAuthGuard>
										</AuthGuard>
									</Route>
									<Redirect from='/' to='/app' />
								</Switch>
							</BrowserRouter>
						</ModalProvider>
					</BotProvider>
				</UserProvider>
			</ThemeEditorProvider>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
