import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Text,
    Input,
    FormControl,
    Flex
} from '@chakra-ui/react';
import { useBotContext } from '../../../../contexts/BotContext';
import { Spinner } from '@chakra-ui/react';
import { useColorModeValue } from '@chakra-ui/react';
import { getBotContactInfo } from '../../../../Service';
import { updateBotContactInfo } from '../../../../Service';

import toast from 'react-hot-toast';


function Contact() {
    const bgColor = useColorModeValue("#F4F7FD", "navy.900");
    const textColor = useColorModeValue("navy.700", "white");

    const token = window.localStorage.getItem("user-token");

    const { botId } = useBotContext()

    const [loading, updateLoader] = useState(false)
    const [phone, setPhone] = useState()
    const [tooltip, setTooltip] = useState("")
    const [email, setEmail] = useState()
    const [scheduleLink, setScheduleLink] = useState()


    const getContactInfo = () => {
        updateLoader(true)
        getBotContactInfo(token, botId)
            .then((response) => {
                setTooltip(response.data.tooltip)
                setPhone(response.data.phone_number)
                setEmail(response.data.bot_mail)
                setScheduleLink(response.data.schedule_link)
                updateLoader(false)
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateLoader(false)
            })
    }

    const updateContactInfo = (payload) => {
        updateLoader(true)
        updateBotContactInfo(token, botId, payload)
            .then((response) => {
                setTooltip(response.data.tooltip)
                setPhone(response.data.phone_number)
                setEmail(response.data.bot_mail)
                setScheduleLink(response.data.schedule_link)
                updateLoader(false)
                toast.success("Info updated successfully")
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(error.response.data.message);
                }
                updateLoader(false)
            })
    }

    useEffect(() => {
        getContactInfo()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [botId])

    return (
        <>
            {loading ? <Box mt={5} style={{ 
                display: "flex", 
                justifyContent: "center", 
                alignItems: "center",
                flexDirection: "column" 
             }}>
                <Spinner />
                <Text fontSize="sm" mt={3} fontWeight="normal" color={textColor}>
                        Please Wait...
                </Text>
            </Box> : <Box my={5}>
            <FormControl>
                    <Text mb={2}>Tooltip</Text>
                    <Flex>
                        <Input
                            _focus={{ boxShadow: "none" }}
                            color={textColor}
                            bg={bgColor}
                            style={{ borderRadius: "50px", height: '50px' }}
                            mb={4}
                            type="text"
                            placeholder="Add Tooltip"
                            value={tooltip}
                            onChange={(e) => setTooltip(e.target.value)}
                        />
                    </Flex>
                </FormControl>
                <FormControl>
                    <Text mb={2}>Phone</Text>
                    <Flex>
                        <Input
                            _focus={{ boxShadow: "none" }}
                            color={textColor}
                            bg={bgColor}
                            style={{ borderRadius: "50px", height: '50px' }}
                            mb={4}
                            type="text"
                            placeholder="Add Phone Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </Flex>
                </FormControl>
                <FormControl>
                    <Text mb={2}>Email</Text>
                    <Flex>
                        <Input
                            _focus={{ boxShadow: "none" }}
                            color={textColor}
                            bg={bgColor}
                            style={{ borderRadius: "50px", height: '50px' }}
                            mb={4}
                            type="text"
                            placeholder="Add Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Flex>
                </FormControl>
                <FormControl>
                    <Text mb={2}>Schedule Link</Text>
                    <Flex>
                        <Input
                            _focus={{ boxShadow: "none" }}
                            color={textColor}
                            bg={bgColor}
                            style={{ borderRadius: "50px", height: '50px' }}
                            mb={6}
                            type="text"
                            placeholder="Add Schedule Link"
                            value={scheduleLink}
                            onChange={(e) => setScheduleLink(e.target.value)}
                        />
                    </Flex>
                </FormControl>
                <Button
                    fontSize='sm'
                    fontWeight='500'
                    variant='brand'
                    w='20%'
                    h='50'
                    mb='24px'
                    onClick={() => {
                        updateContactInfo({
                            phone_number: phone,
                            schedule_link: scheduleLink,
                            tooltip: tooltip,
                            bot_mail: email
                        })
                    }}
                >
                    Update
                </Button>
            </Box>}
        </>
    );
}

export default Contact;