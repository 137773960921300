import React from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  IconButton,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { Fragment } from "react";

const AccordionIconButton = () => <AccordionButton as={IconButton} icon={<AccordionIcon />} px="0" minW="0" />;

const index = (props) => {
  const { creditPackage, clicked, setClicked, loading2, handleFormSubmit } = props;
  return (
    <Accordion w="100%" allowMultiple reduceMotion>
      <TableContainer>
        <Table variant="simple">
          <TableCaption>*All prices are in USD and inclusive of tax.</TableCaption>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Pack</Th>
              <Th>Pricing</Th>
              <Th>Credits</Th>
            </Tr>
          </Thead>
          <Tbody>
            {creditPackage.map((item) => (
              <AccordionItem as={Fragment}>
                <Tr>
                  <Td>
                    <AccordionIconButton />
                  </Td>
                  <Td>{item.name}</Td>
                  <Td color="brand.100">${item.amount}</Td>
                  <Td isNumeric="center" align={"center"} fontWeight="bold">
                    {item.credits}
                  </Td>
                </Tr>
                <AccordionPanel as={Tr}>
                  <Td />
                  <Td colSpan="3">
                    <Button
                      fontSize="sm"
                      fontWeight="500"
                      width="200px"
                      height="50px"
                      mt={4}
                      variant="brand"
                      onClick={() => {
                        handleFormSubmit(item.price_id, true, "buy_credits", item.credits);
                        setClicked(item.price_id);
                      }}
                    >
                      {loading2 && clicked === item.price_id ? <Spinner color="white" size="md" /> : "Buy Now"}
                    </Button>
                  </Td>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Accordion>
  );
};

export default index;
