const VoiceCard = (props) => {
  const { message } = props;
  return (
    <audio autoPlay={message?.autoplay ? true : false} controls>
      <source src={message.voice_path} type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default VoiceCard;
