import { createContext, useContext, useState } from "react";

const BotContext = createContext();

export const useBotContext = () => {
  return useContext(BotContext);
};

export const BotProvider = ({ children }) => {
  const [botName, setBotName] = useState("");
  const [botColor, setBotColor] = useState("");
  const [botId, setBotId] = useState("");
  const [dbType, setDbType] = useState();
  const [botType, setBotType] = useState();
  const [isRunning, setIsRunning] = useState(false);
  const [elabsApiKey, setElabsApiKey] = useState(null);
  const [elabsVoice, setElabsVoice] = useState(null);

  const updateBotName = (name) => setBotName(name);
  const updateBotColor = (color) => setBotColor(color);
  const updateBotId = (id) => setBotId(id);
  const updateDbType = (type) => setDbType(type);
  const updateBotType = (type) => setBotType(type);
  const updateIsRunning = (state) => setIsRunning(state);
  const updateElabsApiKey = (key) => setElabsApiKey(key);
  const updateElabsVoice = (voice) => setElabsVoice(voice);

  return (
    <BotContext.Provider
      value={{
        botName,
        botColor,
        botId,
        dbType,
        botType,
        isRunning,
        elabsApiKey,
        elabsVoice,
        updateBotName,
        updateBotColor,
        updateBotId,
        updateDbType,
        updateBotType,
        updateIsRunning,
        updateElabsApiKey,
        updateElabsVoice,
      }}
    >
      {children}
    </BotContext.Provider>
  );
};
