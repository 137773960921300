import { Button, Flex, FormControl, Input, Spinner, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { fetchNotificationEmails, addNotificationEmail, removeNotificationEmail } from "../../../../Service";
import toast from "react-hot-toast";
import { useBotContext } from "../../../../contexts/BotContext";

const Notifications = () => {
  const bgColor = useColorModeValue("#F4F7FD", "navy.900");
  const textColor = useColorModeValue("navy.700", "white");
  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const token = window.localStorage.getItem("user-token");
  const { botId } = useBotContext();

  const addEmail = async () => {
    if (!email) {
      toast.error("Please enter an email address");
      return;
    }
    if (emails.includes(email.toLowerCase())) {
      toast.error("Email already exists");
      return;
    }

    setButtonLoading(true);
    await addNotificationEmail(token, botId, { email: email.toLowerCase() })
      .then((res) => {
        setEmails([...emails, email.toLowerCase()]);
        setEmail("");
        setButtonLoading(false);
        toast.success(res.data.message);
      })
      .catch((error) => {
        setButtonLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const removeEmail = async (_email) => {
    setButtonLoading(true);
    await removeNotificationEmail(token, botId, { email: _email })
      .then(() => {
        setEmails(emails.filter((__email) => __email !== _email));
        setButtonLoading(false);
        toast.success("Email removed successfully");
      })
      .catch((error) => {
        setButtonLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const fetchEmail = async () => {
    setLoading(true);
    await fetchNotificationEmails(token, botId)
      .then((res) => {
        setEmails(res.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchEmail();
  }, []);
  return (
    <>
      {loading ? (
        <Flex alignItems={"center"} mt={4}>
          <Spinner mx={"auto"} />
        </Flex>
      ) : (
        <Stack gap={"20px"} mt={2}>
          <Text fontSize="3xl" fontWeight={"bold"}>
          Enter emails to receive chat notifications.
          </Text>
          {emails.length > 0 && (
            <Flex gap={"10px"} wrap={"wrap"}>
              {emails.map((_email, _index) => (
                <Flex key={_index} gap={0}>
                  <Button disabled={true} borderRightRadius={0}>
                    {_email}
                  </Button>
                  <Button onClick={() => removeEmail(_email)} borderLeftRadius={0}>
                    X
                  </Button>
                </Flex>
              ))}
            </Flex>
          )}
          <FormControl>
            <Flex>
              <Input
                required={true}
                _focus={{ boxShadow: "none" }}
                color={textColor}
                bg={bgColor}
                style={{ borderRadius: "50px", height: "50px" }}
                mr={2}
                type="email"
                name="notification_email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button onClick={addEmail} variant="brand" fontSize="sm" fontWeight="500" w="20%" h="50" mb="24px">
                {buttonLoading ? <Spinner /> : "Save"}
              </Button>
            </Flex>
          </FormControl>
        </Stack>
      )}
    </>
  );
};

export default Notifications;
