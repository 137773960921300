import React, { useState, useEffect } from "react";
import { Box, Button, Input, Text, Link } from "@chakra-ui/react";
import { useBotContext } from "../../../../../contexts/BotContext";
import { FaLongArrowAltRight } from "react-icons/fa";
import { updateAuraDatabase, fetchAuraDatabase, updateDefaultDatabase } from "../../../../../Service";
import { Spinner } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/react";
import Banner from "../../../../../theme/components/banner";

import toast from "react-hot-toast";

function Aura() {
  const token = window.localStorage.getItem("user-token");
  const { botId, dbType, updateDbType } = useBotContext();
  const [loading, updateLoader] = useState(false);
  const bgColor = useColorModeValue("#F4F7FD", "navy.900");
  const textColor = useColorModeValue("navy.700", "white");

  const [formData, setFormData] = useState({
    dbUri: "",
    dbUser: "",
    dbPass: "",
  });

  const changeFormData = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const updateDatabase = () => {
    updateLoader(true);
    updateAuraDatabase(token, botId, {
      dbUri: formData.dbUri,
      dbUser: formData.dbUser,
      dbPass: formData.dbPass,
    })
      .then(() => {
        toast.success("AuraDS database updated successfully");
        updateLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateLoader(false);
      });
  };

  const fetchDatabase = () => {
    updateLoader(true);
    fetchAuraDatabase(token, botId)
      .then((response) => {
        setFormData({ ...response.data });
        updateLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateLoader(false);
      });
  };

  const setDefault = (id) => {
    updateLoader(true);
    updateDefaultDatabase(token, botId, {
      dbType: id,
    })
      .then((response) => {
        toast.success("Default database updated successfully");
        updateDbType(response.data.dbType);
        updateLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateDbType(0);
        updateLoader(false);
      });
  };

  useEffect(() => {
    fetchDatabase(botId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [botId]);

  const LinkStyle = {
    textDecoration: "none",
    color: "#007FC3",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  };

  return (
    <>
      {true ? (
        <Banner text="coming soon" />
      ) : (
        <Box my={3}>
          <Box mb={8}>
            <Text color={textColor} fontSize="xl" fontWeight="bold">
              AuraDS Settings
            </Text>
            <Text color={textColor}>Enter your AuraDS DB details here</Text>
          </Box>
          <Input
            color={textColor}
            bg={bgColor}
            _focus={{ boxShadow: "none" }}
            style={{ borderRadius: "50px", height: "50px" }}
            mb={3}
            type="text"
            placeholder="bolt://00.000.0.000:0000"
            onChange={(e) => {
              changeFormData("dbUri", e.target.value);
            }}
            value={formData.dbUri}
          />
          <Input
            color={textColor}
            bg={bgColor}
            _focus={{ boxShadow: "none" }}
            style={{ borderRadius: "50px", height: "50px" }}
            mb={3}
            type="text"
            placeholder="Username"
            onChange={(e) => {
              changeFormData("dbUser", e.target.value);
            }}
            value={formData.dbUser}
          />
          <Input
            color={textColor}
            bg={bgColor}
            _focus={{ boxShadow: "none" }}
            style={{ borderRadius: "50px", height: "50px" }}
            mb={8}
            type="text"
            placeholder="Password"
            onChange={(e) => {
              changeFormData("dbPass", e.target.value);
            }}
            value={formData.dbPass}
          />
          <Box>
            <Button onClick={() => updateDatabase()} fontSize="sm" variant="brand" fontWeight="500" w="20%" h="50" mb="24px" mr={5}>
              {loading ? <Spinner color="white" /> : "Update"}
            </Button>
            {dbType === 2 ? (
              <Button onClick={() => setDefault(0)} fontSize="sm" fontWeight="500" w="20%" h="50" mb="24px">
                {loading ? <Spinner color="white" /> : "Remove as default"}
              </Button>
            ) : (
              <Button onClick={() => setDefault(2)} fontSize="sm" fontWeight="500" w="20%" h="50" mb="24px">
                {loading ? <Spinner color="white" /> : "Set as default"}
              </Button>
            )}
          </Box>

          <Link href="https://neo4j.com/docs/aura/aurads/create-instance" target="_blank" rel="noreferrer" style={LinkStyle}>
            Read Documentation <FaLongArrowAltRight />
          </Link>
        </Box>
      )}
    </>
  );
}

export default Aura;
