import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { 
    Spinner, 
    useDisclosure, 
    Modal, 
    ModalOverlay, 
    ModalContent, 
    ModalHeader, 
    ModalCloseButton, 
    ModalBody, 
    ModalFooter, 
    Button, 
    Box, 
    Text, 
    List, 
    ListItem, 
    ListIcon 
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import axios from "axios";
import { BASE_URL } from "../Service";

/**
 * Guards user authentication status.
 * @returns {Promise} - A promise that resolves with the authentication status response.
 */
export const guard = (auth_token) => {
    return axios.post(`${BASE_URL}/auth/token-validation`, {
        "token": auth_token,
    }, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${auth_token}`,
        }
    });
};

/**
 * Extracts and parses the updates data from the response.
 * @param {Object} response - The axios response object.
 * @returns {Array} - Parsed updates data.
 */
const extractUpdatesData = (response) => {
    const { content } = response.data;

    try {
        if (typeof content === 'string') {
            const formattedContent = content.replace(/'/g, '"');
            return JSON.parse(formattedContent);
        }
        return content;
    } catch (error) {
        console.error('Failed to parse content:', error);
        return [];
    }
};

/**
 * A component that guards routes by checking authentication status.
 * If the user is authenticated, it renders the children components.
 * If not authenticated, it redirects to the sign-in page.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The children components to render if authenticated.
 * @returns {ReactNode} - The authentication guard component.
 */
const AuthGuard = ({ children }) => {
    const auth_token = window.localStorage.getItem("user-token");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [popupContent, setPopupContent] = useState([]);

    useEffect(() => {
        if (auth_token) {
            guard(auth_token)
                .then((res) => {
                    setIsAuthenticated(true);
                    setIsLoading(false);

                    // Make API call to get updates after authentication
                    axios.get(`${BASE_URL}/auth/updates`, {
                        headers: {
                            'Authorization': `Token ${auth_token}`
                        }
                    })
                    .then((updateResponse) => {
                        const contentType = updateResponse.headers['content-type'];
                       
                        if (contentType && contentType.includes('application/json')) {
                            const updates = extractUpdatesData(updateResponse);
                            const { show_popup } = updateResponse.data;
                    
                            if (show_popup) {
                                setPopupContent(updates);
                                onOpen();
                                console.log('Popup should open now');
                            }
                            
                        } else {
                            console.error('Unsupported content type:', contentType);
                        }
                    })
                    .catch((updateError) => {
                        console.error('Failed to fetch updates:', updateError);
                    });

                })
                .catch((err) => {
                    setIsAuthenticated(false);
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [auth_token, onOpen]);

    if (isLoading) {
        // Show loading state, you can customize this according to your needs
        return <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgba(255, 255, 255, 0.5)',
        }}>
            <Spinner variant='brand' />
        </div>;
    }

    if (!isAuthenticated) {
        window.localStorage.removeItem("user-token");
        history.push("/signin");
    }

    return (
        <>
            {children}
            <Modal isOpen={isOpen} onClose={onClose}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader >Big Changes at BotWorx!</ModalHeader><hr></hr>
    <ModalCloseButton />
    <ModalBody><n></n>
      {popupContent.map((update, index) => (
        <Box key={index} mb={4}>
          <Text fontSize="lg" fontWeight="medium">{update.title}</Text>
          <List spacing={2} mt={2}>
            {update.points.map((point, idx) => {
              // Apply bold styling for points in "Memory Storage Options"
              if (update.title === "Memory Storage Options") {
                const [boldText, normalText] = point.split(": ");
                return (
                  <ListItem key={idx}>
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    <Text as="span" fontWeight="bold">{boldText}:</Text> {normalText}
                  </ListItem>
                );
              } else {
                return (
                  <ListItem key={idx}>
                    <ListIcon as={CheckCircleIcon} color="green.500" />
                    {point}
                  </ListItem>
                );
              }
            })}
          </List>
        </Box>
      ))}
    </ModalBody>
    <ModalFooter>
     
    </ModalFooter>
  </ModalContent>
</Modal>

        </>
    );
};

export default AuthGuard;
