import React, { useEffect, useState } from "react";
import { Box, Button, Text, Input, FormControl, Flex, Select, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { useBotContext } from "../../../../contexts/BotContext";
import { useUserContext } from "../../../../contexts/UserContext";
import { Spinner } from "@chakra-ui/react";
import { FaRegCopy } from "react-icons/fa";
import { useColorModeValue } from "@chakra-ui/react";
import { BASE_URL } from "../../../../Service";
import { updateBotAPIKey, saveBotDiscordToken, startDiscordBot, stopDiscordBot, getContextModels, getIntegrations } from "../../../../Service";
import { updateBotQuestions, updateContextWindow, updateModels } from "../../../../Service";

import toast from "react-hot-toast";

function Integrations() {
  const bgColor = useColorModeValue("#F4F7FD", "navy.900");
  const textColor = useColorModeValue("navy.700", "white");

  const token = window.localStorage.getItem("user-token");

  const { botId, botName, isRunning, updateIsRunning } = useBotContext();

  const { free } = useUserContext();
  const [discordToken, setDiscordToken] = useState();
  const [apiKey, setApiKey] = useState();
  const [apiID, setApiID] = useState();
  const [questionOne, setQuestionOne] = useState();
  const [questionTwo, setQuestionTwo] = useState();
  const [questionThree, setQuestionThree] = useState();
  const [loading, updateLoader] = useState(false);
  const [buttonLoading, updateButtonLoader] = useState(false);
  const [discordLoading, setDiscordLoading] = useState(false);
  const [contextModels, setContextModels] = useState([]);

  const updateAPIKey = () => {
    updateLoader(true);
    updateBotAPIKey(token, apiID)
      .then((response) => {
        toast.success("API Key updated successfully");
        setApiKey(response.data.api_key);
        setApiID(response.data.api_id);
        updateLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateLoader(false);
      });
  };

  const saveDiscordToken = () => {
    if (discordToken === undefined || discordToken === "" || discordToken === null) {
      toast.error("Please enter a valid discord token");
      return;
    }
    updateButtonLoader(true);
    saveBotDiscordToken(
      token,
      {
        discord_token: discordToken,
      },
      botId
    )
      .then((response) => {
        toast.success("Discord Token saved successfully");
        setDiscordToken(response.data.discord_token);
        updateButtonLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateButtonLoader(false);
      });
  };

  const startBot = () => {
    setDiscordLoading(true);
    startDiscordBot(token, botId)
      .then(() => {
        toast.success("Bot started successfully");
        updateIsRunning(true);
        setDiscordLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateIsRunning(false);
        setDiscordLoading(false);
      });
  };

  const stopBot = () => {
    setDiscordLoading(true);
    stopDiscordBot(token, botId)
      .then(() => {
        toast.success("Bot stopped successfully");
        updateIsRunning(false);
        setDiscordLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateIsRunning(true);
        setDiscordLoading(false);
      });
  };

  const updateQuestions = () => {
    updateButtonLoader(true);
    updateBotQuestions(token, botId, {
      question_one: questionOne,
      question_two: questionTwo,
      question_three: questionThree,
    })
      .then((response) => {
        toast.success("Questions updated successfully");
        fetchIntegrations();
        updateButtonLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateButtonLoader(false);
      });
  };

  const fetchContextModels = () => {
    updateLoader(true);
    getContextModels(token, botId)
      .then((response) => {
        setContextModels(response.data);
        updateLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateLoader(false);
      });
  };

  const fetchIntegrations = () => {
    updateLoader(true);
    getIntegrations(token, botId)
      .then((response) => {
        setDiscordToken(response.data.discord_token);
        setApiKey(response.data.api_key);
        setApiID(response.data.api_id);
        setQuestionOne(response.data.question_one);
        setQuestionTwo(response.data.question_two);
        setQuestionThree(response.data.question_three);
        updateLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateLoader(false);
      });
  };

  const uupdateContextWindow = (id) => {
    updateLoader(true);
    updateContextWindow(token, botId, id)
      .then((response) => {
        toast.success(response.data.message);
        setContextModels(response.data.context_window);
        updateLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateLoader(false);
      });
  };

  const uupdateModels = (model) => {
    updateLoader(true);
    updateModels(token, botId, model)
      .then((response) => {
        toast.success(response.data.message);
        setContextModels(response.data.models);
        updateLoader(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        }
        updateLoader(false);
      });
  };

  useEffect(() => {
    fetchIntegrations();
    fetchContextModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Box
          mt={5}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Spinner />
          <Text fontSize="sm" mt={3} fontWeight="normal" color={textColor}>
            Please Wait...
          </Text>
        </Box>
      ) : (
        <Box my={5}>
          <FormControl marginBottom="20px">
            <Text mb={2}>Context Window</Text>
            <Flex>
              <Select onChange={(e) => uupdateContextWindow(e.target.value)} placeholder="Select option" defaultValue={contextModels?.default_cw}>
                {!free ? (
                  contextModels.context_window?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })
                ) : (
                  <option value={contextModels?.default_cw}>{contextModels?.default_cw}</option>
                )}
              </Select>
            </Flex>
          </FormControl>
          <FormControl marginBottom="20px">
            <Text mb={2}>Chat Model</Text>
            <Flex>
              <Select onChange={(e) => uupdateModels(e.target.value)} placeholder="Select option" defaultValue={contextModels?.default_model?.id}>
                {!free ? (
                  contextModels.models?.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })
                ) : (
                  <option value={contextModels?.default_model?.id}>{contextModels?.default_model?.name}</option>
                )}
              </Select>
            </Flex>
          </FormControl>
          <FormControl>
            <Text mb={2}>Bot Id</Text>
            <Flex>
              <Input
                _focus={{ boxShadow: "none" }}
                color={textColor}
                bg={bgColor}
                style={{ borderRadius: "50px", height: "50px" }}
                mr={2}
                type="text"
                placeholder="Bot Id"
                value={botId}
                disabled
              />
              <Button
                fontSize="sm"
                fontWeight="500"
                w="20%"
                h="50"
                mb="24px"
                onClick={() => {
                  navigator.clipboard.writeText(botId);
                  toast.success("Copied!");
                }}
              >
                <FaRegCopy />
              </Button>
            </Flex>
          </FormControl>
          <FormControl>
            <Text mb={2}>API Key</Text>
            <Flex>
              <Input
                _focus={{ boxShadow: "none" }}
                color={textColor}
                bg={bgColor}
                style={{ borderRadius: "50px", height: "50px" }}
                mr={2}
                type="text"
                placeholder="API Key"
                value={apiKey}
              />
              <Button
                fontSize="sm"
                fontWeight="500"
                w="20%"
                h="50"
                mb="24px"
                onClick={() => {
                  updateAPIKey();
                }}
              >
                Regenerate
              </Button>
            </Flex>
          </FormControl>
          <Tabs>
            <TabList>
              <Tab>Website</Tab>
              <Tab>Discord</Tab>
              <Tab>API</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <>
                  <Box mt={0}>
                    <Text style={{ marginBottom: "10px" }}>Predefined Questions</Text>
                    <FormControl>
                      <Flex>
                        <Input
                          _focus={{ boxShadow: "none" }}
                          color={textColor}
                          bg={bgColor}
                          style={{ borderRadius: "50px", height: "50px" }}
                          mr={2}
                          type="text"
                          placeholder="Question No 1"
                          value={questionOne}
                          onChange={(e) => setQuestionOne(e.target.value)}
                        />
                        <Input
                          _focus={{ boxShadow: "none" }}
                          color={textColor}
                          bg={bgColor}
                          style={{ borderRadius: "50px", height: "50px" }}
                          mr={2}
                          type="text"
                          placeholder="Question No 2"
                          value={questionTwo}
                          onChange={(e) => setQuestionTwo(e.target.value)}
                        />
                        <Input
                          _focus={{ boxShadow: "none" }}
                          color={textColor}
                          bg={bgColor}
                          style={{ borderRadius: "50px", height: "50px" }}
                          mr={2}
                          type="text"
                          placeholder="Question No 3"
                          value={questionThree}
                          onChange={(e) => setQuestionThree(e.target.value)}
                        />
                        <Button
                          fontSize="sm"
                          fontWeight="500"
                          w="40%"
                          h="50"
                          mb="24px"
                          onClick={() => {
                            updateQuestions();
                          }}
                        >
                          {buttonLoading ? <Spinner /> : "Save"}
                        </Button>
                      </Flex>
                    </FormControl>
                  </Box>
                  <Box mt={2}>
                    Embed code
                    <Text color={textColor}>
                      <Box mt={3} bg={bgColor} style={{ padding: "10px" }}>
                        <code>&lt;script src="https://botworx-files.s3.amazonaws.com/btwrx.js"&gt;&lt;/script&gt;</code>
                        <code>
                          &lt;script&gt; const chatbot = new Chatbot(`{botId}`, `{apiKey}`); chatbot.start(); &lt;/script&gt;
                        </code>
                      </Box>
                    </Text>
                  </Box>
                </>
              </TabPanel>
              <TabPanel>
                <Box>
                  <FormControl>
                    <Text mb={2}>Discord Token</Text>
                    <Flex>
                      <Input
                        _focus={{ boxShadow: "none" }}
                        color={textColor}
                        bg={bgColor}
                        style={{ borderRadius: "50px", height: "50px" }}
                        mr={2}
                        type="text"
                        placeholder="Discord Token"
                        value={discordToken}
                        onChange={(e) => setDiscordToken(e.target.value)}
                      />
                      <Button
                        fontSize="sm"
                        fontWeight="500"
                        w="20%"
                        h="50"
                        mb="24px"
                        onClick={() => {
                          saveDiscordToken();
                        }}
                      >
                        {buttonLoading ? <Spinner /> : "Save"}
                      </Button>
                    </Flex>
                  </FormControl>
                  <Box>
                    {isRunning === true ? (
                      <Button
                        onClick={() => {
                          stopBot();
                        }}
                        fontSize="sm"
                        fontWeight="500"
                        w="20%"
                        h="50"
                        mb="24px"
                      >
                        {discordLoading ? <Spinner /> : "Stop Bot"}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          startBot();
                        }}
                        fontSize="sm"
                        variant="brand"
                        fontWeight="500"
                        w="20%"
                        h="50"
                        mb="24px"
                      >
                        {discordLoading ? <Spinner /> : "Start Bot"}
                      </Button>
                    )}
                    <Text color={textColor} fontSize="sm">
                      It will take 2-3 minutes to start/stop the bot.
                    </Text>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel>
                <>
                  <Box mt={2}>
                    cRUL Request
                    <Text color={textColor}>
                      <Box mt={3} bg={bgColor} style={{ padding: "10px" }}>
                        <pre style={{ display: "flex", flexDirection: "column", fontSize: "16px" }}>
                          <code>
                            {`curl -X POST "${BASE_URL}/chat"
-H "Content-Type: multipart/form-data"
-F "audio=@/path/to/your/audiofile.wav"
-F "data = {
  "bot_id": "${botId}",
  "api_key": "${apiKey}",
  "message": "Hi, ${botName}",
  "username": "John Doe",
  "user_id": "5ere3e3f32vf342r2f",
  "is_voice": True,
  "image_request":False,
}"`}
                          </code>
                        </pre>
                      </Box>
                    </Text>
                  </Box>
                </>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </>
  );
}

export default Integrations;
