import { DownloadIcon, ViewIcon } from "@chakra-ui/icons";
import { Box, IconButton, Text } from "@chakra-ui/react";

const PdfDocumentCard = (props) => {
  const { message } = props;

  const truncateFileName = (fileName, maxLength = 15) => {
    const lastDotIndex = fileName.lastIndexOf(".");
    const name = lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;
    const extension = lastDotIndex !== -1 ? fileName.slice(lastDotIndex) : "";

    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...${extension}`;
    }
    return fileName;
  };

  return (
    <Box
      position="relative"
      bg="white"
      borderRadius="md"
      boxShadow="md"
      padding="10px"
      margin="8px auto" // Center the Box horizontally
      display="flex"
      flexDirection="column" // Stack elements vertically
      alignItems="center" // Center items horizontally
      justifyContent="flex-start" // Align to the start
      marginLeft={message.isSent ? "auto" : "0"} // Align to the right for sent messages
      marginRight={message.isSent ? "0" : "auto"} // Align to the left for received messages
      transition="background 0.3s ease" // Add smooth transition effect for hover
      _hover={{ bg: "gray.100" }} // Light background on hover
    >
      <Text
        fontSize="md"
        fontWeight="bold"
        color="black"
        mb="2" // Margin bottom for spacing
      >
        {truncateFileName(message?.document_path.split("/").pop())}
      </Text>
      <Box
        width="100%"
        height="150px" // Adjust height as needed
        overflow="hidden" // Hide overflow content
        marginBottom="10px" // Space between preview and buttons
      >
        <Box
          as="iframe" // Use an iframe to display the PDF preview
          src={message.document_path}
          width="100%"
          height="100%"
          border="none"
        />
      </Box>
      <Box display="flex" justifyContent="center" gap="2">
        {" "}
        {/* Center icons */}
        <IconButton
          icon={<DownloadIcon color="black" />}
          onClick={() => {
            fetch(message.document_path)
              .then((response) => response.blob())
              .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = message?.document_path.split("/").pop();
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
              })
              .catch(() => alert("Failed to download file."));
          }}
          aria-label="Download Document"
          size="sm" // Smaller button size
          bg="transparent"
          _hover={{ bg: "gray.200" }} // Light background on hover
        />
        <IconButton
          icon={<ViewIcon color="black" />}
          onClick={() => {
            window.open(message.document_path, "_blank");
          }}
          aria-label="Preview Document"
          size="sm" // Smaller button size
          bg="transparent"
          _hover={{ bg: "gray.200" }} // Light background on hover
        />
      </Box>
    </Box>
  );
};

export default PdfDocumentCard;
