import { useState } from "react";

import { Image, Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/react";

const ImageCard = (props) => {
  const { message } = props;
  const [imageSrc, setImageSrc] = useState("");
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Image
        onClick={(e) => {
          setImageSrc(e.target.src);
          setOpenModal(true);
        }}
        borderRadius="12px"
        cursor="pointer"
        boxSize="130px"
        src={message.image_path}
        fallbackSrc="https://s3.amazonaws.com/statics.botworx/media/images/aww-snap.gif"
      />

      {openModal && (
        <Modal isOpen={true} onClose={() => setOpenModal(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <Image src={imageSrc} boxSize="500px" />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ImageCard;
