import React from "react";
import { Box, Heading, Text, Divider, useColorModeValue, Link } from "@chakra-ui/react";
import { FaLongArrowAltRight } from "react-icons/fa";

const TrainingDoc = () => {
  const bgColor = useColorModeValue("#fff", "navy.800");
  const textColor = useColorModeValue("navy.700", "white");
  const LinkStyle = {
    textDecoration: "none",
    color: "#007FC3",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  };

  return (
    <Box p={8} maxW="auto" mx="auto" mt={20} boxShadow="lg" borderRadius="md" bg={bgColor}>
      <Heading as="h1" size="xl" mb={6} textAlign="center" color={textColor}>
        AI Model Training Documentation
      </Heading>
      <Divider mb={6} />

      <Heading as="h2" size="lg" mb={4} color={textColor}>
        Introduction
      </Heading>
      <Text mb={6} color={textColor}>
        This document outlines the steps and considerations involved in training AI models. Properly training a model is crucial for achieving high
        performance and accuracy in tasks such as classification, regression, and more.
      </Text>
      <Box
        as="iframe"
        src="https://www.youtube.com/embed/q9XI0Lo-SWE?si=kguMxD8aiYWi10XC"
        width="100%"
        sx={{
          aspectRatio: "16/9",
        }}
      />
      <Link mt={3} href="https://docs.botworx.io" target="_blank" rel="noreferrer" style={LinkStyle}>
        Official Developer's Documentation <FaLongArrowAltRight />
      </Link>
    </Box>
  );
};

export default TrainingDoc;
